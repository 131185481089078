// FooterComponent.js
import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterComponent = () => {
    return (
        <Footer style={{ textAlign: 'center', backgroundColor: '#000', color: '#fff', padding: '20px 50px' }}>
            {/* <Row gutter={16}>
                <Col span={8}>
                    <h3>About Dayze</h3>
                    <p>Dayze is your personal planner and digital memory keeper, helping you make the most of every day.</p>
                </Col>
                <Col span={8}>
                    <h3>Quick Links</h3>
                    <a href="/features">Features</a><br />
                    <a href="/pricing">Pricing</a><br />
                    <a href="/about">About Us</a><br />
                    <a href="/contact">Contact</a>
                </Col>
                <Col span={8}>
                    <h3>Contact Us</h3>
                    <p>Email: contact@dayze.com</p>
                    <p>Phone: +1 234 567 890</p>
                </Col>
            </Row> */}
            <div style={{ marginTop: '20px' }}>
                © {new Date().getFullYear()} Dayze - All Rights Reserved
            </div>
        </Footer>
    );
};

export default FooterComponent;
