import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm.js';
import Navbar from './Navbar.js';
import { db } from './firebaseConfig.js'; // Removed 'app'
import { FieldValue } from 'firebase/firestore';

const stripePromise = loadStripe('pk_live_51M52pGBnfFVgetXZHqhiKKaMBVNi872VcpkvTfx1NasatrJdZ96WmbD4YaPgQWNENBqxTRsvhm02rAUUClvyCPrU00EQ3zzAre');

const PaymentMethodsPage = () => {
  const [userProfile, setUserProfile] = useState(null);
  const handlePaymentCompleted = async (paymentMethodId, dayzeCoinsPurchased) => {
    // Handle post-payment actions, such as updating the user's DayzeCoins balance
    console.log('Payment completed:', paymentMethodId);
  
    // Assuming you have the user ID:
    const userId = userProfile.id;
  
    try {
      await updateUserDayzeCoin(userId, dayzeCoinsPurchased);
  
      // Update the userProfile state with the updated data
      const updatedUserProfile = { ...userProfile, dayzeCoin: userProfile.dayzeCoin + dayzeCoinsPurchased };
      setUserProfile(updatedUserProfile);
    } catch (error) {
      console.error('Error updating user DayzeCoin balance:', error);
    }
  };

  const updateUserDayzeCoin = async (userId, dayzeCoinsPurchased) => {
    const userRef = db.collection('userProfiles').doc(userId);
  
    return userRef.update({
      dayzeCoin: FieldValue.increment(dayzeCoinsPurchased),
    });
  };

  useEffect(() => {
    // Fetch the user profile data here and set it to the state
    // setUserProfile(response.data);
  }, []);

  return (
    <div>
      <Navbar userProfile={userProfile} />
      {/* <h1>Payment Methods</h1> */}
      <Elements stripe={stripePromise}>
        <CheckoutForm onPaymentCompleted={handlePaymentCompleted} />
      </Elements>
    </div>
  );
};

export default PaymentMethodsPage;
