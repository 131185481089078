import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const SectionOne = () => {
    const faqs = [
        {
            question: 'What is Dayze?',
            answer: 'Dayze is a unique PWA (Progressive Web App) that combines a personal planner, social network, and digital memory keeper. It helps you organize your life, connect with others, and capture meaningful moments all in one place.'
        },
        {
            question: 'How is Dayze different from other planning apps?',
            answer: 'Dayze goes beyond simple planning by integrating social features and memory-keeping capabilities. It transforms everyday moments into adventures and helps you live life more fully, rather than just organizing it.'
        },
        {
            question: 'What are the key features of Dayze?',
            answer: 'Dayze offers daily event tracking, memory capturing, social sharing, customizable themes, cross-device syncing, and secure storage of your personal data. It is designed to make every day extraordinary!'
        },
        {
            question: 'Is Dayze free to use?',
            answer: 'Dayze offers a free version with essential features. Premium features are available for those who want to enhance their experience, but they are optional. No hidden fees!'
        },
        {
            question: 'How can I access Dayze?',
            answer: 'As a PWA, Dayze can be accessed through any modern web browser on your phone, tablet, or computer. You can also add it to your home screen for quick access, giving you an app-like experience without the need for installation from an app store.'
        },
        {
            question: 'Can I share my plans and memories with friends?',
            answer: 'Absolutely! Dayze has built-in social features that allow you to share your plans, experiences, and memories with friends and family, fostering a more connected and engaging lifestyle.'
        },
        {
            question: 'How does Dayze keep my data secure?',
            answer: 'We take your privacy seriously. Dayze uses state-of-the-art security measures to ensure that your personal data and cherished memories are securely stored and always private.'
        },
        {
            question: 'Can I customize my Dayze experience?',
            answer: 'Yes! Dayze offers customization options including themes, reminder settings, and event organization. You can tailor your Dayze experience to fit your unique style and preferences.'
        },
        {
            question: 'Does Dayze work offline?',
            answer: 'As a PWA, Dayze offers some offline functionality. You can view and add content even without an internet connection, and it will sync once you are back online.'
        },
        {
            question: 'How can I get started with Dayze?',
            answer: 'Getting started is easy! Simply visit our website, create an account, and start adding your daily events and memories. You can also explore our tutorial section for tips on making the most of Dayze.'
        }
    ];

    return (
        <div className="section-one">
            <div className="section-content">
                <h1 className="title">FAQs</h1>
                <Collapse accordion>
                    {faqs.map((faq, index) => (
                        <Panel header={faq.question} key={index}>
                            <p>{faq.answer}</p>
                        </Panel>
                    ))}
                </Collapse>
            </div>
        </div>
    );
}

export default SectionOne;