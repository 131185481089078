import React from 'react';
import { Layout, Typography, Divider } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Content style={{ 
        padding: '50px', 
        maxWidth: '800px', 
        margin: 'auto',
        backgroundColor: '#ffffff',
        color: '#000000'
      }}>
        <Title style={{ color: '#000000' }}>Privacy Policy</Title>
        <Divider />
        <Paragraph style={{ color: '#000000' }}>Date updated: May 8, 2023</Paragraph>
        <Title level={2} style={{ color: '#000000' }}>1. Information Collection and Use</Title>
        <Paragraph>
          We collect several different types of information for various purposes to provide and improve our Service to you.
        </Paragraph>
        <Title level={2} style={{ color: '#000000' }}>2. Types of Data Collected</Title>
        <Title level={3} style={{ color: 'black' }}>2.1. Personal Data</Title>
        <Paragraph>
          While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
        </Paragraph>
        <ul style={{ color: 'black' }}>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Profile picture</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <Title level={3} style={{ color: 'black' }}>2.2. Usage Data</Title>
        <Paragraph>
          We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
        </Paragraph>
        <Title level={2} style={{ color: '#000000' }}>3. Use of Data</Title>
        <Paragraph>
          We use the collected data for various purposes:
        </Paragraph>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our Service</li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent, and address technical issues</li>
        </ul>
        <Title level={2} style={{ color: '#000000' }}>4. Disclosure of Data</Title>
        <Title level={3}>4.1. Legal Requirements</Title>
        <Paragraph>
          We may disclose your Personal Data in the good faith belief that such action is necessary to:
        </Paragraph>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Phi Digital LLC</li>
          <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>To protect the personal safety of users of the Service or the public</li>
          <li>To protect against legal liability</li>
        </ul>
        <Title level={2} style={{ color: '#000000' }}>5. Security of Data</Title>
        <Paragraph>
          The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
        </Paragraph>
        <Title level={2} style={{ color: '#000000' }}>7. Changes to This Privacy Policy</Title>
        <Paragraph>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </Paragraph>
        <Paragraph>
          We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
        </Paragraph>
        <Paragraph>
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </Paragraph>
        <Title level={2} style={{ color: '#000000' }}>8. Contact Us</Title>
        <Paragraph>
          If you have any questions about this Privacy Policy, please contact us by email at <a href="mailto:support@dayze.me">support@dayze.me</a>.
        </Paragraph>
      </Content>
    </Layout>
  );
};

export default PrivacyPolicy;