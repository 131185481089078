import React from 'react';
import { Typography, Divider, Layout } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const Terms = () => {
  return (
    <Layout>
      <Content style={{ padding: '50px', maxWidth: '800px', margin: '0 auto' }}>
        <Title level={1}>Terms and Conditions</Title>
        <Divider />
        <Paragraph>Date updated: May 8, 2023</Paragraph>
        <Title level={2}>1. Introduction</Title>
        <Paragraph>
          These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with Dayze (the "Service") operated by Phi Digital LLC ("us", "we", "our").
        </Paragraph>
        <Paragraph>
          Please read these Terms and Conditions carefully before using our website (the "Service").
        </Paragraph>
        <Paragraph>
          Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
        </Paragraph>
        <Paragraph>
          By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
        </Paragraph>
        <Title level={2}>2. DayzeCoin</Title>
        <Paragraph>
          The Service allows you to purchase DayzeCoin, which can be used within the Service. We reserve the right to change the pricing and availability of DayzeCoin at any time. All sales of DayzeCoin are final and non-refundable.
        </Paragraph>
        <Title level={2}>3. User Content</Title>
        <Paragraph>
          We are not responsible for any user-generated content, including but not limited to text, images, video, or any other content posted or shared by users of the Service. We do not endorse or support any opinions, statements, or advice provided by users.
        </Paragraph>
        <Title level={2}>4. Termination</Title>
        <Paragraph>
          We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
        </Paragraph>
        <Paragraph>
          All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
        </Paragraph>
        <Title level={2}>5. Governing Law</Title>
        <Paragraph>
          These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.
        </Paragraph>
        <Paragraph>
          Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
        </Paragraph>
        <Title level={2}>6. Changes</Title>
        <Paragraph>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </Paragraph>
        <Paragraph>
          By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
        </Paragraph>
        <Title level={2}>7. Contact Us</Title>
        <Paragraph>
        If you have any questions about these Terms, please contact us at <a href="mailto:support@dayze.me">support@dayze.me</a>.
        </Paragraph>
      </Content>
    </Layout>
  );
};
export default Terms;