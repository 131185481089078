import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginPage from './LoginPage.js';
import HomePage from './HomePage.js';
import SignUpPage from './SignUpPage.js';
import SuccessScreen from './SuccessScreen.js';
import EditProfile from './EditProfile.js';
import PublicProfile from './PublicProfile.js';
import PaymentMethodsPage from './PaymentMethodsPage.js';
import CheckoutWithElements from './Checkout.js';
import Terms from './Terms.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import ForgotPassword from './ForgotPassword.js';
import LandingPage from './LandingPage.js';
import Settings from './components/Settings.js'
import { analytics } from './firebaseConfig.js';
import { logEvent } from 'firebase/analytics';

function RootRedirect() {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setIsAuthChecked(true);
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, []); // Added a dependency array here

  return isAuthChecked ? <Navigate to={isLoggedIn ? "/home" : "/landing"} replace /> : null;
}

function App() {
  useEffect(() => {
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootRedirect />} /> {/* Use RootRedirect here */}
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/success" element={<SuccessScreen />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/:handle" element={<PublicProfile />} />
        <Route path="/payment-methods" element={<PaymentMethodsPage />} />
        <Route path="/checkout" element={<CheckoutWithElements />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/settings" element={<Settings />} />

      </Routes>
    </Router>
  );
}

export default App;