import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, addDoc, Timestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Button, Switch } from 'antd';
import { ShareAltOutlined, InstagramOutlined } from '@ant-design/icons';  // Modified import here
import { daysToNextBirthday } from './daysToNextBirthday.js';
import defaultAvatar from './assets/images/default-avatar.svg';
import { ReactComponent as BrandLogo } from './assets/images/dayze-logo-full.svg';
import ThemeSwitcher from './ThemeSwitcher.js';
import SunSign from './SunSign.js';
import FollowingModal from './components/FollowingModal.js';
import FollowersModal from './components/FollowersModal.js';


const PublicProfile = () => {
    const { handle } = useParams();
    const [userProfile, setUserProfile] = useState(null);
    const [loggedInUserProfile, setLoggedInUserProfile] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isPolarisEnabled, setIsPolarisEnabled] = useState(false);
    const [isFollowingModalVisible, setFollowingModalVisible] = useState(false);
    const [isFollowersModalVisible, setFollowersModalVisible] = useState(false);
    const [showFollowersSetting, setShowFollowersSetting] = useState(true);
    const [showZodiacSign, setShowZodiacSign] = useState(false);
    const [showDaysToBirthday, setShowDaysToBirthday] = useState(false);


    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();
    const loggedInUserUID = auth.currentUser?.uid;

    const getUpcomingBirthday = (birthdate) => {
        const today = new Date();
        const upcomingBirthday = new Date(Date.UTC(today.getFullYear(), birthdate.getUTCMonth(), birthdate.getUTCDate(), 0, 0, 0));

        if (today > upcomingBirthday) {
            upcomingBirthday.setUTCFullYear(today.getUTCFullYear() + 1);
        }

        return upcomingBirthday;
    };

    // Function to parse bio and create links for user handles
    const parseBio = (bio) => {
        if (!bio) return bio;

        const userHandleRegex = /@(\w+)/g;
        const parts = bio.split(userHandleRegex);

        return parts.map((part, index) => {
            if (index % 2 === 1) {
                // It's a username
                return <Link key={index} to={`/${part}`}>@{part}</Link>;
            }
            return part; // Normal text
        });
    };

    const handleFollow = async () => {
        await Promise.all([
            updateDoc(doc(db, 'userProfiles', loggedInUserUID), {
                following: arrayUnion(userProfile.uid)
            }),
            updateDoc(doc(db, 'userProfiles', userProfile.uid), {
                followers: arrayUnion(loggedInUserUID)
            })
        ]);

        const userProfileBirthday = new Date(userProfile.birthday);
        const upcomingBirthdayDate = getUpcomingBirthday(userProfileBirthday);

        const newEvent = {
            uid: auth.currentUser.uid,
            eventType: "birthday",
            relatedUser: userProfile.uid,
            date: Timestamp.fromDate(upcomingBirthdayDate),
            title: `${userProfile.name}'s Birthday! 🎈`
        };

        const eventsCollection = collection(db, 'events');
        await addDoc(eventsCollection, newEvent);

        setIsFollowing(true);
    };

    const handleUnfollow = async () => {
        await Promise.all([
            updateDoc(doc(db, 'userProfiles', loggedInUserUID), {
                following: arrayRemove(userProfile.uid)
            }),
            updateDoc(doc(db, 'userProfiles', userProfile.uid), {
                followers: arrayRemove(loggedInUserUID)
            })
        ]);
        setIsFollowing(false);
    };

    useEffect(() => {
        const fetchUserProfileAndSettings = async () => {
            const db = getFirestore();
            const userProfilesRef = collection(db, 'userProfiles');
            const lowercaseHandle = handle.toLowerCase();
            const q = query(userProfilesRef, where('handle', '==', lowercaseHandle));
        
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const userProfileData = querySnapshot.docs[0].data();
                setUserProfile(userProfileData);

                 // Check if the logged-in user is following this profile
                setIsFollowing(userProfileData.followers?.includes(loggedInUserUID));


                // Fetch settings of the profile being viewed
                const settingsRef = doc(db, 'userSettings', userProfileData.uid);
                const settingsDoc = await getDoc(settingsRef);
                if (settingsDoc.exists()) {
                    const settingsData = settingsDoc.data();
                    setShowFollowersSetting(settingsData.showFollowers);
                    setShowZodiacSign(settingsData.showZodiacSign);
                    setShowDaysToBirthday(settingsData.showDaysToBirthday);
                }

                // Set website title
                document.title = `${userProfileData.name} (@${userProfileData.handle}) • Dayze`;
            } else {
                document.title = "Profile Not Found • Dayze";
            }
        };

        fetchUserProfileAndSettings();

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);

                const fetchLoggedInUserProfile = async () => {
                    const docRef = doc(db, 'userProfiles', user.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setLoggedInUserProfile(docSnap.data());
                    }
                };
                fetchLoggedInUserProfile();
            } else {
                setIsLoggedIn(false);
            }
        });

        if (userProfile?.polaris) {
            setIsPolarisEnabled(userProfile.polaris);
        }

        return () => {
            unsubscribe();
        };
    }, [handle, auth, db, loggedInUserUID, userProfile]);

    const handlePolarisToggle = async (checked) => {
        setIsPolarisEnabled(checked);
        try {
            await updateDoc(doc(db, 'userProfiles', userProfile.uid), {
                polaris: checked
            });
        } catch (error) {
            console.error("Error updating Polaris status: ", error);
        }
    };

    const defaultAvatarUrl = defaultAvatar;

    const handleShare = () => {
        console.log("Share button clicked");

        if (navigator.share) {
            console.log("navigator.share supported");

            navigator
                .share({
                    title: `@${handle} | Dayze`,
                    url: `https://dayze.me/${handle}`,
                })
                .then(() => {
                    console.log("Share successful");
                })
                .catch((error) => {
                    console.log("Error sharing", error);
                });
        } else {
            console.log("Share not supported on this browser");
        }
    };

    const handleFollowClick = async () => {
        if (!isLoggedIn) {
            console.log("User needs to be logged in to follow");
            return;
        }

        if (isFollowing) {
            await handleUnfollow();
        } else {
            await handleFollow();
        }
    };

    const handleViewAs = () => {
        if (userProfile && loggedInUserProfile?.isAdmin) {
            navigate('/home', { state: { viewAsUserId: userProfile.uid } });
        }
    };

    return (
        <>
            <ThemeSwitcher />
            <div className="auth-container" style={{marginTop: '60px', display: 'flex', flexDirection: 'column', minHeight: '100vh', justifyContent: 'flex-start' }}>
                {isLoggedIn && loggedInUserProfile?.isAdmin && loggedInUserProfile?.uid !== userProfile?.uid && (
                    <>
                        <Button
                            className="view-as-button"
                            onClick={handleViewAs}
                        >
                            View as {userProfile?.name}
                        </Button>
                        <div className="polaris-toggle">
                            <span>Polaris</span>
                            <Switch checked={isPolarisEnabled} onChange={handlePolarisToggle} />
                        </div>
                    </>
                )}
                {userProfile ? (
                    // <Card className="card-public">
                    <>
                        <div>
                            <img
                                src={userProfile.profileImageUrl || defaultAvatarUrl}
                                alt="Profile"
                                style={{ width: '96px', height: '96px', borderRadius: '50%', objectFit: 'cover' }}
                                onClick={() => setModalVisible(true)}
                            />
                            {isModalVisible && (
                                <div className="modal" onClick={() => setModalVisible(false)}>
                                    <img
                                        src={userProfile.profileImageUrl || defaultAvatarUrl}
                                        alt="Profile"
                                        style={{ width: '90vw', height: '90vw' }}
                                    />
                                </div>
                            )}
                        </div>
                        <h2 className="handleName">{userProfile.handle ? `@${userProfile.handle}` : userProfile.name}</h2>
                        <p className="user-bio">{parseBio(userProfile.bio)}</p>
                       
                        {showZodiacSign && <SunSign birthday={new Date(userProfile.birthday)} />}

                        {showFollowersSetting && (
    <>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-20px' }}>
            <div className="followers-container" onClick={() => setFollowersModalVisible(true)}>
                <p className="following-followers-count">{userProfile && userProfile.followers ? userProfile.followers.length : 0}</p>
                <p className="following-followers-title">{userProfile && userProfile.followers && userProfile.followers.length === 1 ? 'Follower' : 'Followers'}</p>
            </div>
            <div className="following-container" onClick={() => setFollowingModalVisible(true)}>
                <p className="following-followers-count">{userProfile && userProfile.following ? userProfile.following.length : 0}</p>
                <p className="following-followers-title">Following</p>
            </div>
        </div>

        <FollowersModal
            uid={userProfile?.uid}
            isModalVisible={isFollowersModalVisible}
            setIsModalVisible={setFollowersModalVisible}
        />
        <FollowingModal
            uid={userProfile?.uid}
            isModalVisible={isFollowingModalVisible}
            setIsModalVisible={setFollowingModalVisible}
        />
    </>
)}

                        {showDaysToBirthday && userProfile.birthday && (
                            <p>Days to Birthday: {daysToNextBirthday(new Date(userProfile.birthday))}</p>
                        )}

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            {isLoggedIn && loggedInUserProfile?.uid !== userProfile?.uid && (
                                <Button type={isFollowing ? undefined : "primary"} onClick={handleFollowClick}>
                                    {isFollowing ? 'Unfollow' : 'Follow'}
                                </Button>
                            )}
                            {userProfile?.instagramHandle && (
                                <a
                                    href={`https://instagram.com/${userProfile.instagramHandle}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <InstagramOutlined className="social-icon" style={{ margin: '6px 8px 0 8px' }} />
                                </a>
                            )}
                        </div>
                    </>
                    // </Card>
                ) : (
                    <p>Loading...</p>
                )}
                {isLoggedIn && (
                    <Link to="/home">
                        <Button className="fixedButtonBackToHome">
                            <strong>Back to Home</strong>
                        </Button>
                    </Link>
                )}
                <Button
                    className="share-button"
                    onClick={handleShare}
                    icon={<ShareAltOutlined />}
                    shape="circle"
                    type="primary"
                />
                <Link to="/">
                    <BrandLogo className='BrandLogoFooter' />
                </Link>
            </div>
        </>
    );
};

export default PublicProfile;
