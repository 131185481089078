import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Alert, Card } from 'antd';
import ThemeSwitcher from './ThemeSwitcher.js';

import './AuthPage.css';
import { ReactComponent as BrandLogo } from './assets/images/dayze-logo-full.svg';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Check your inbox.');
      setError('');
    } catch (error) {
      setMessage('');
      setError('Error sending password reset email. Please try again.');
    }
  };

  return (
    <div>
      <ThemeSwitcher />
      <BrandLogo className="BrandLogo" />
      <h1 className="login-title">Forgot Password</h1>
      <div className="auth-container">
        <Card className="card-container-login">

          <Form
            className="no-auto-zoom"
            onFinish={handleSubmit}>
            <Form.Item
              className="ant-form-item-explain-error"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid email!',
                },
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input
                type="email"
                style={{ height: '50px' }}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className='branded-button'
                style={{ height: '50px', width: '100%', marginTop: '4px' }}
                type="primary"
                htmlType="submit">
                <strong>Send Password Reset Email</strong>
              </Button>
            </Form.Item>
          </Form>
          <p>
            Back to <Link to="/login">Login</Link>
          </p>
          {message && <Alert message={message} type="success" showIcon />}
          {error && <Alert message={error} type="error" showIcon />}
        </Card>
      </div>
    </div>
  );
};

export default ForgotPassword;
