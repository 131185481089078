import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Typography, Checkbox, Form, notification } from 'antd';
import { Timestamp, serverTimestamp } from "firebase/firestore";
import { getFirestore, collection, addDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import moment from 'moment';
import useUpdateEventCount from './hooks/useUpdateEventCount.js';
import './LoadingScreen.css';
import './styles/EventModal.css';

const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

const EventModal = ({ uid, events }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [eventTitle, setEventTitle] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventDate, setEventDate] = useState(moment().format('YYYY-MM-DD'));
    const [eventTime, setEventTime] = useState(moment().format('HH:mm:ss'));
    const [isTimeEditable, setIsTimeEditable] = useState(false);
    const [eventEndDate, setEventEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [eventEndTime, setEventEndTime] = useState(moment().add(1, 'hours').format('HH:mm:ss'));
    const [noEndDate, setNoEndDate] = useState(false);
    const [eventType, setEventType] = useState('');
    const [relatedUserHandle, setRelatedUserHandle] = useState('');
    const [eventLocation, setEventLocation] = useState('');

    useUpdateEventCount(uid, events);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleOk();
    };

    const requestNotificationPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            console.log("Notification permission granted.");
            // Here you might want to handle the subscription to push notifications
        } else {
            console.log("Notification permission denied.");
        }
        return permission;
    };

    useEffect(() => {
        if (isModalVisible && !isTimeEditable) {
            const interval = setInterval(() => {
                setEventTime(moment().format('HH:mm:ss'));
                setEventEndTime(moment().add(1, 'hours').format('HH:mm:ss'));
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isModalVisible, isTimeEditable]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleToUID = async (handle) => {
        const db = getFirestore();
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('handle', '==', handle));
        const userSnapshot = await getDocs(q);
        if (!userSnapshot.empty) {
            return userSnapshot.docs[0].id;
        }
        return null;
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            setIsModalVisible(false);

            const firestoreEventDateTime = eventDate
                ? moment(eventDate).set({
                    hour: eventTime ? moment(eventTime, 'HH:mm').hour() : 0,
                    minute: eventTime ? moment(eventTime, 'HH:mm').minute() : 0,
                    second: eventTime ? moment(eventTime, 'HH:mm:ss').second() : 0,
                    millisecond: 0,
                }).toDate()
                : null;

            const firestoreEventEndDateTime = noEndDate
                ? null
                : moment(eventEndDate).set({
                    hour: eventEndTime ? moment(eventEndTime, 'HH:mm').hour() : 0,
                    minute: eventEndTime ? moment(eventEndTime, 'HH:mm').minute() : 0,
                    second: eventEndTime ? moment(eventEndTime, 'HH:mm:ss').second() : 0,
                    millisecond: 0,
                }).toDate();

            const relatedUID = await handleToUID(relatedUserHandle);

            const eventData = {
                title: eventTitle,
                description: eventDescription,
                location: eventLocation,
                date: firestoreEventDateTime ? Timestamp.fromDate(firestoreEventDateTime) : serverTimestamp(),
                endDate: firestoreEventEndDateTime ? Timestamp.fromDate(firestoreEventEndDateTime) : null,
                endTime: firestoreEventEndDateTime ? Timestamp.fromDate(firestoreEventEndDateTime) : null,
                eventType: eventType,
                relatedUser: relatedUID
            };

            onEventSave(eventData);

            // Resetting state variables to their default values
            setEventTitle('');
            setEventDescription('');
            setEventDate(moment().format('YYYY-MM-DD'));
            setEventTime(moment().format('HH:mm:ss'));
            setEventEndDate(moment().format('YYYY-MM-DD'));
            setEventEndTime(moment().add(1, 'hours').format('HH:mm:ss'));
            setNoEndDate(false);
            setEventType('');
            setRelatedUserHandle('');
            setEventLocation('');

            form.resetFields();

        }).catch(info => {
            console.log('Validate Failed:', info);
        });

        const subscribeToNotifications = async () => {
            try {
                const registration = await navigator.serviceWorker.ready;
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: vapidPublicKey,
                });
                return subscription;
            } catch (error) {
                console.error('Error subscribing to notifications:', error);
            }
        };

        const manageUserSubscriptionInFirestore = async (subscription) => {
            const db = getFirestore();
            const userSubscriptionsRef = collection(db, 'userSubscriptions');
            const subscriptionObject = JSON.parse(JSON.stringify(subscription));
    
            // Query for existing subscription
            const q = query(userSubscriptionsRef, where('uid', '==', uid));
            const querySnapshot = await getDocs(q);
    
            if (querySnapshot.empty) {
                // No existing subscription, create new
                await addDoc(userSubscriptionsRef, {
                    uid: uid,
                    subscription: subscriptionObject,
                    timestamp: serverTimestamp()
                });
            } else {
                // Existing subscription found, handle as needed
                console.log('Existing subscription found for this user');
                // Optional: Update existing subscription
                // const existingDoc = querySnapshot.docs[0];
                // await updateDoc(existingDoc.ref, { subscription: subscriptionObject });
            }
        };

         // Notification permission and subscription logic
         const permission = await requestNotificationPermission();
         if (permission === "granted") {
             const subscription = await subscribeToNotifications();
             if (subscription) {
                 await manageUserSubscriptionInFirestore(subscription);
             }
         }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onEventSave = async (eventData) => {
        try {
            const db = getFirestore();
            const eventsRef = collection(db, 'events');
            const docRef = await addDoc(eventsRef, eventData);
            const docId = docRef.id;
            await updateDoc(docRef, { ...eventData, id: docId, uid });
    
            notification.success({
                message: 'Event Added',
                description: 'Your event has been successfully added!',
                duration: 4,
                placement: 'bottom',
                className: 'ant-notification-notice-dark'
            });
        } catch (error) {
            console.error('Error adding event: ', error);
            notification.error({
                message: 'Error',
                description: 'There was an error adding the event.',
                duration: 4,
                placement: 'bottom',
                className: 'ant-notification-notice-dark'
            });
        }
    };

    return (
        <div>
            <Button type="primary" onClick={showModal}>
                Add Event
            </Button>
            <Modal className='event-modal'
                title="Add Event"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Save
                    </Button>,
                ]}
            >
                <Form form={form} className="event-modal" onSubmit={handleSubmit}>
                    <Input
                        className="form-input"
                        placeholder=" "
                        value={eventTitle}
                        onChange={(e) => setEventTitle(e.target.value)}
                    />
                    <label className="form-label">Event Title</label>

                    <Form.Item className="form-item">
                        <Input.TextArea
                            className="form-input-textarea"
                            placeholder=" "
                            value={eventDescription}
                            onChange={(e) => setEventDescription(e.target.value)}
                            autoSize={{ minRows: 3, maxRows: 12 }}
                        />
                        <label className="form-label-textarea">Description</label>
                    </Form.Item>

                    <Form.Item>
                        <Input
                            className="form-input"
                            placeholder=" "
                            value={eventLocation}
                            onChange={(e) => setEventLocation(e.target.value)}
                        />
                        <label className="form-label">Location</label>
                    </Form.Item>

                    <Form.Item>
                        <Input
                            className="form-input"
                            placeholder=" "
                            value={eventType}
                            onChange={(e) => setEventType(e.target.value)}
                        />
                        <label className="form-label">Event Type</label>
                    </Form.Item>

                    <Form.Item>
                        <Input
                            className="form-input"
                            placeholder=" "
                            value={relatedUserHandle}
                            onChange={(e) => setRelatedUserHandle(e.target.value)}
                        />
                        <label className="form-label">Related People</label>
                    </Form.Item>

                    <div
                        style={{
                            marginTop: '1rem',
                        }}
                    >
                        <Typography.Text strong>Starts</Typography.Text>
                    </div>
                    <Input
                        type="date"
                        value={eventDate}
                        style={{
                            marginTop: '0.5rem'
                        }}
                        onChange={(e) => setEventDate(e.target.value)}
                    />
                    <Input
                        type="time"
                        placeholder="Event Time"
                        value={eventTime}
                        style={{
                            marginTop: '1rem',
                        }}
                        onChange={(e) => {
                            setIsTimeEditable(true);
                            setEventTime(e.target.value);
                        }}
                        onClick={() => setIsTimeEditable(true)}
                    />
                    <div
                        style={{
                            marginTop: '1rem',
                        }}
                    >
                        <Typography.Text strong>Ends</Typography.Text>
                        <Checkbox
                            style={{ marginLeft: '1rem' }}
                            checked={noEndDate}
                            onChange={(e) => setNoEndDate(e.target.checked)}
                        >
                            <span
                                className='dark-span'>
                                No End Date
                            </span>
                        </Checkbox>
                    </div>
                    {!noEndDate && (
                        <>
                            <Input
                                type="date"
                                value={eventEndDate}
                                style={{
                                    marginTop: '0.5rem'
                                }}
                                onChange={(e) => setEventEndDate(e.target.value)}
                            />
                            <Input
                                type="time"
                                placeholder="Event End Time"
                                value={eventEndTime}
                                style={{
                                    marginTop: '1rem',
                                }}
                                onChange={(e) => setEventEndTime(e.target.value)}
                            />
                        </>
                    )}

                    <Button type="primary" htmlType="submit" style={{ display: "none" }}>
                        Hidden Submit Button
                    </Button>
                </Form>
            </Modal>
            {uid === null && <p>Loading...</p>}
        </div>
    );
};

export default EventModal;