// SignUpPage.js
import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { auth } from './firebaseConfig.js';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Card } from 'antd';
import { Helmet } from 'react-helmet';
import './AuthPage.css'; // Import the shared CSS file
import { ReactComponent as BrandLogo } from './assets/images/dayze-logo-full.svg';
import ThemeSwitcher from './ThemeSwitcher.js';
import StarField from './components/StarField.js';

const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [name, setName] = useState('');
    const [isRegistering, setIsRegistering] = useState(false); // New state for registration loading indicator

    const navigate = useNavigate();
    const db = getFirestore(); // Initialize Firestore instance

    const createUserProfile = async (uid, email, name) => {
        const userProfileRef = doc(db, 'userProfiles', uid);
        await setDoc(userProfileRef, {
            email: email,
            name: name,
            createdAt: new Date().toISOString(),
        });
    };

    const registerNewUser = async (event, email, password, name) => {
        event.preventDefault();
        setIsRegistering(true); // Set to true when registration starts
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            console.log('User registered:', userCredential.user);

            await createUserProfile(userCredential.user.uid, email, name);

            navigate('/success');
        } catch (error) {
            setError('Error registering a new user');
            console.error('Error registering a new user', error);
        } finally {
            setIsRegistering(false); // Set back to false when registration is complete or errors out
        }
    };

    return (
        <div>
            <ThemeSwitcher />
            <Helmet>
                <title>Dayze - Sign Up</title>
                <meta name="description" content="Sign up for Dayze and experience life in days." />
                <meta name="keywords" content="Dayze, day calculator, dayze me, sign up, life in days" />
            </Helmet>
            <div className="starfield-container">
                    <StarField />
                </div>
            <Link to="/">
                <BrandLogo className="BrandLogo" />
            </Link>
            <h1 className="login-title">Sign Up</h1>
            <div className="auth-container">
                
                <Card className="card-container-sign-up">
                    <div>
                        {error !== null && <div>{error}</div>}
                        <Form>
                            <Form.Item className="no-auto-zoom">
                                <Input
                                    style={{ height: '50px' }}
                                    type="email"
                                    id="userEmail"
                                    value={email}
                                    placeholder="Email"
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </Form.Item>
                            <Form.Item className="no-auto-zoom">
                                <Input
                                    style={{ height: '50px' }}
                                    type="text"
                                    id="userName"
                                    value={name}
                                    placeholder="Full Name"
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </Form.Item>
                            <Form.Item className="no-auto-zoom">
                                <Input.Password
                                    style={{ height: '50px' }}
                                    type="password"
                                    id="userPassword"
                                    value={password}
                                    placeholder="Password"
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </Form.Item>
                            <p
                                style={{ width: '280px' }}
                            >
                                By registering, you agree with the<br /><Link to="/terms">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link>
                            </p>
                            <Form.Item className="no-auto-zoom">
                                <Button
                                    className="branded-button"
                                    style={{ height: '50px', width: '100%', marginTop: '16px' }}
                                    type="primary"
                                    onClick={(event) => registerNewUser(event, email, password, name)}
                                    disabled={isRegistering} // Optional: disable button while registering
                                >
                                    {isRegistering ? <strong>Signing Up</strong> : <strong>Sign Up</strong>}  {/* Conditional rendering of button text */}
                                </Button>
                            </Form.Item>
                        </Form>
                        <p>
                            Back to <Link to="/login">Login</Link>
                        </p>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default SignUpPage;
