import React, { useState } from 'react';
import { DatePicker, Button } from 'antd';
import { Link } from 'react-router-dom';

const DayAgeCalculator = () => {
    const [birthdate, setBirthdate] = useState(null);
    const [dayAge, setDayAge] = useState(null);
    const [daysToNextBirthday, setDaysToNextBirthday] = useState(null);

    const calculateDayAge = (date) => {
        if (!date) {
            setDayAge(null);
            setDaysToNextBirthday(null);
            return;
        }

        const birthDate = date.toDate();
        const today = new Date();
        const differenceInTime = today.getTime() - birthDate.getTime();
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
        setDayAge(differenceInDays);

        calculateDaysToNextBirthday(birthDate, today);
    };

    const calculateDaysToNextBirthday = (birthdate) => {
        const today = new Date();
        let nextBirthday = new Date(today.getFullYear(), birthdate.getMonth(), birthdate.getDate());
        if (today > nextBirthday) {
            nextBirthday.setFullYear(today.getFullYear() + 1);
        }
        const timeToNextBirthday = nextBirthday.getTime() - today.getTime();
        const daysUntilNextBirthday = Math.ceil(timeToNextBirthday / (1000 * 3600 * 24));
        setDaysToNextBirthday(daysUntilNextBirthday);
    };

    return (
        <div className="day-age-calculator">
            <h2 className='title'>Find Out Your Age in Days!</h2>
            <DatePicker
                onChange={(date) => {
                    setBirthdate(date);
                    calculateDayAge(date);
                }}
                value={birthdate}
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                style={{ borderRadius: '12px',  marginTop: '20px' }}
            />
            {dayAge !== null && (
                <>
                    <p>You are <strong>{dayAge}</strong> days old!</p>
                    <p>And there are{daysToNextBirthday < 31 && " only"} <strong>{daysToNextBirthday}</strong> days until your birthday!</p>
                    <p className="description">
                        You've lived <strong>{dayAge}</strong> days. Imagine what you can do with the next thousand!
                        Join <strong>Dayze</strong> to capture every moment.
                    </p>
                    <Link to="/signup">
                        <Button
                            type="primary"
                            className="cta-btn"
                        >
                            Sign Up
                        </Button>
                    </Link>
                </>
            )}
        </div>
    );
}

export default DayAgeCalculator;