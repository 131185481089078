// hooks/useUpdatePeopleCount.js
import { useEffect } from 'react';
import { db } from '../firebaseConfig.js';
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';

const useUpdatePeopleCount = (uid) => {
  useEffect(() => {
    const updatePeopleCount = async () => {
      // Ensure we have a valid UID
      if (!uid) {
        return;
      }

      try {
        // Reference to the 'people' collection
        const peopleRef = collection(db, 'people');
        // Query to count the number of people associated with the user's UID
        const q = query(peopleRef, where('uid', '==', uid));
        const querySnapshot = await getDocs(q);
        const peopleCount = querySnapshot.docs.length;

        // Reference to the user's profile document in the 'userProfiles' collection
        const userProfileRef = doc(db, 'userProfiles', uid);

        // Update the user's profile with the new people count
        await updateDoc(userProfileRef, {
          peopleCount: peopleCount
        });
      } catch (error) {
        console.error("Error updating user's people count:", error);
      }
    };

    updatePeopleCount();
  }, [uid]); // Dependency array to trigger effect when uid changes
};

export default useUpdatePeopleCount;
