import React, { useRef, useMemo, useCallback } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const Stars = () => {
  const starsRef = useRef();

  const starGeometry = useMemo(() => {
    const starVertices = new Float32Array(150000);
    for (let i = 0; i < 50000; i++) {
      const i3 = i * 3;
      starVertices[i3] = (Math.random() - 0.5) * 1000;
      starVertices[i3 + 1] = (Math.random() - 0.5) * 2000;
      starVertices[i3 + 2] = (Math.random() - 0.5) * 1000;
    }
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));
    return geometry;
  }, []);

  const animateStars = useCallback(() => {
    if (starsRef.current) {
      starsRef.current.rotation.z -= 0.001;
      starsRef.current.rotation.y += 0.0001;
    }
  }, []);

  useFrame(animateStars);

  const starSize = 0.5;

  const starTexture = useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 16;
    canvas.height = 16;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#FFF';
    ctx.beginPath();
    ctx.arc(8, 8, 8, 0, 2 * Math.PI);
    ctx.fill();
    return new THREE.CanvasTexture(canvas);
  }, []);

  return (
    <points ref={starsRef} geometry={starGeometry}>
      <pointsMaterial color={0xFFFFFF} size={starSize} map={starTexture} transparent />
    </points>
  );
};

const StarField = () => {
  return (
    <Canvas style={{ background: 'transparent' }}>
      <Stars />
    </Canvas>
  );
};

export default React.memo(StarField);