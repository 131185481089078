// CurrentTime.js
import React, { useState, useEffect } from 'react';

const CurrentTime = () => {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      
      // Extracting and formatting individual components
      const year = now.getFullYear();
      const month = now.toLocaleString('en-US', { month: 'long' });
      const day = now.getDate();
      const hour = now.getHours();
      const minute = now.getMinutes().toString().padStart(2, '0');
      const second = now.getSeconds().toString().padStart(2, '0');
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const formattedHour = hour % 12 || 12;
      const timezone = now.toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ')[2];

      // Combining components into final formatted string
      const formattedTime = `${month} ${day}, ${year} ${formattedHour}:${minute}:${second} ${ampm} ${timezone}`;
      
      setCurrentTime(formattedTime);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return <p className="current-time" style={{ fontSize: '13px' }}>{currentTime}</p>;

};

export default CurrentTime;
