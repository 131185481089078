import React, { useState, useEffect } from 'react';

const emojis = ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇'];

const RandomEmoji = () => {
    const [emoji, setEmoji] = useState("");

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * emojis.length);
        setEmoji(emojis[randomIndex]);
    }, []); 

    return <span>{emoji}</span>;
};

export default RandomEmoji;
