import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Card, Alert } from 'antd';


const stripePromise = loadStripe('pk_live_51M52pGBnfFVgetXZHqhiKKaMBVNi872VcpkvTfx1NasatrJdZ96WmbD4YaPgQWNENBqxTRsvhm02rAUUClvyCPrU00EQ3zzAre');

function Checkout() {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const price = queryParams.get('price');
  const amount = queryParams.get('amount');

  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const clientSecret = await createPaymentIntent(price * 100);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      setMessageType('error');
      setMessage(`Payment failed: ${error.message}`);
      console.log('[error]', error);
    } else {
      setMessageType('success');
      setMessage('Payment successful!');
      console.log('[PaymentIntent]', paymentIntent);
    }
  };

  const createPaymentIntent = async (price) => {
    const response = await fetch('/.netlify/functions/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ price }),
    });

    const data = await response.json();
    return data.clientSecret;
  };

  console.log('Message:', message);
  console.log('MessageType:', messageType);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card title={`Pay $${price} for ${amount} DayzeCoin`} style={{ width: 300 }}>
        {message && <Alert message={message} type={messageType} showIcon />}
        <CardElement />
        <Button type="primary" onClick={handleSubmit} disabled={!stripe}>
          Pay
        </Button>
      </Card>
    </div>
  );
}

export default function CheckoutWithElements() {
  return (
    <Elements stripe={stripePromise}>
      <Checkout />
    </Elements>
  );
}
