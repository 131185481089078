// serviceWorkerRegistration.js

// Function to simplify logging
const logServiceWorker = (message, error) => {
  if (error) {
    console.error(`[Service Worker] Error: ${message}`, error);
  } else {
    console.log(`[Service Worker] ${message}`);
  }
};

export const register = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
          // ... registration success handler
        })
        .catch((error) => {
          // ... registration error handler
        });
    });
  }
};

export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
        .then(() => logServiceWorker('Unregistered'))
        .catch((error) => logServiceWorker('Unregistration failed', error));
    });
  }
};
