import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Typography, Form } from 'antd';
import moment from 'moment';
import { getFirestore, updateDoc, doc } from 'firebase/firestore';
import './styles/EventModal.css';

const EditEventModal = ({ handleCloseEditModal, event = {}, onEventSave }) => {
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [title, setTitle] = useState(event.title || '');
    const [description, setDescription] = useState(event.description || '');
    const [date, setDate] = useState(event.date ? moment(event.date.toDate()).format('YYYY-MM-DD') : '');
    const [time, setTime] = useState(event.date ? moment(event.date.toDate()).format('HH:mm:ss') : '');
    const [endDate, setEndDate] = useState(event.endDate ? moment(event.endDate.toDate()).format('YYYY-MM-DD') : '');
    const [endTime, setEndTime] = useState(event.endDate ? moment(event.endDate.toDate()).format('HH:mm:ss') : '');
    const [location, setLocation] = useState(event.location || '');
    const [eventType, setEventType] = useState(event.eventType || '');
    const [relatedUserHandle, setRelatedUserHandle] = useState(event.relatedUserHandle || '');

    useEffect(() => {
        if (isEditModalVisible) {
            setTitle(event.title);
            setDescription(event.description);
            setLocation(event.location || '');
            setDate(event.date ? moment(event.date.toDate()).format('YYYY-MM-DD') : '');
            setTime(event.date ? moment(event.date.toDate()).format('HH:mm:ss') : '');
            setEndDate(event.endDate ? moment(event.endDate.toDate()).format('YYYY-MM-DD') : '');
            setEndTime(event.endDate ? moment(event.endDate.toDate()).format('HH:mm:ss') : '');
            setEventType(event.eventType || '');
            setRelatedUserHandle(event.relatedUserHandle || '');
        }
    }, [event, isEditModalVisible]);

    const handleOk = async (e) => {
        e.preventDefault();

        const updatedEvent = {
            ...event,
            title,
            description,
            location,
            eventType,
            relatedUserHandle,
            date: moment(`${date} ${time}`).toDate(),
            endDate: endDate && endTime ? moment(`${endDate} ${endTime}`).toDate() : null,
        };

        console.log(updatedEvent);
        await updateEvent(updatedEvent);
        handleCloseEditModal(e);
        handleCancel();
    };

    const showModal = () => {
        setIsEditModalVisible(true);
    };

    const handleCancel = () => {
        setIsEditModalVisible(false);
    };

    const updateEvent = async (eventData) => {
        try {
            const db = getFirestore();
            const eventDoc = doc(db, 'events', eventData.id);
            await updateDoc(eventDoc, eventData);
            onEventSave(eventData);
        } catch (error) {
            console.error('Error updating event: ', error);
        }
    };

    return (
        <div>
            <Button onClick={showModal}>
                Edit
            </Button>
            <Modal className='event-modal'
                title="Edit Event"
                open={isEditModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Save
                    </Button>,
                ]}
            >
                <form className="no-auto-zoom">
                    <Form.Item>
                        <Input
                            className="form-input"
                            placeholder=" "
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <label className="form-label">Event Title</label>
                    </Form.Item>
                    <Form.Item>
                        <Input.TextArea
                            className="form-input-textarea"
                            placeholder=" "
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            autoSize={{ minRows: 3, maxRows: 10 }}
                        />
                        <label className="form-label-textarea">Description</label>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className="form-input"
                            placeholder=" "
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        <label className="form-label">Location</label>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className="form-input"
                            placeholder=" "
                            value={eventType}
                            onChange={(e) => setEventType(e.target.value)}
                        />
                         <label className="form-label">Event Type</label>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className="form-input"
                            placeholder=" "
                            value={relatedUserHandle}
                            onChange={(e) => setRelatedUserHandle(e.target.value)}
                        />
                        <label className="form-label">Related People</label>
                    </Form.Item>
                    <div
                        style={{
                            marginTop: '1rem',
                        }}
                    >
                        <Typography.Text strong>Starts</Typography.Text>
                    </div>
                    <Input
                        style={{
                            marginTop: '0.5rem',
                        }}
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                    <Input
                        style={{
                            marginTop: '1rem',
                        }}
                        type="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                    />
                    <div
                        style={{
                            marginTop: '1rem',
                        }}
                    >
                        <Typography.Text strong>Ends</Typography.Text>
                    </div>
                    <Input
                        style={{
                            marginTop: '0.5rem',
                        }}
                        type='date'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <Input
                        style={{
                            marginTop: '1rem',
                        }}
                        type="time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                    />
                </form>
            </Modal>
        </div>
    );
};

export default EditEventModal;