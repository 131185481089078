import React, { useState, useEffect } from 'react';
import { Modal, List, Avatar, Button } from 'antd';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import '../styles/FollowingModal.css';

const FollowersModal = ({ uid, isModalVisible, setIsModalVisible }) => {
    const [followersUsers, setFollowersUsers] = useState([]);

    useEffect(() => {
        const fetchFollowersUsers = async () => {
            const db = getFirestore();
            const usersRef = collection(db, 'userProfiles');
            const q = query(usersRef, where('following', 'array-contains', uid));

            const querySnapshot = await getDocs(q);
            const users = querySnapshot.docs.map(doc => doc.data());
            setFollowersUsers(users);
        };

        if (uid && isModalVisible) {
            fetchFollowersUsers();
        }
    }, [uid, isModalVisible]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Modal
            title="Followers"
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            className="centered-modal-title"
        >
            <List
                className="custom-list"
                itemLayout="horizontal"
                dataSource={followersUsers}
                renderItem={user => (
                    <List.Item
                        actions={[
                            // You might want to update this button depending on whether the current user is following them
                            <Button key="follow" type="primary" disabled onClick={() => {/* Follow/Unfollow logic here */}}>
                                Following
                            </Button>
                        ]}
                    >
                        <List.Item.Meta
                            className="custom-list-item-meta"
                            avatar={
                                <Link to={`/${user.handle}`} onClick={() => setIsModalVisible(false)}>
                                    <Avatar src={user.profileImageUrl} size={48} />
                                </Link>
                            }
                            title={
                                <Link to={`/${user.handle}`} className="meta-title" onClick={() => setIsModalVisible(false)}>
                                    {user.name}
                                </Link>
                            }
                            description={<span className="meta-description">{`@${user.handle}`}</span>}
                        />
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default FollowersModal;
