import React, { useState, useEffect } from 'react';
import { Card, Form, Switch, Typography, Button, notification } from 'antd';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Navbar from '../Navbar.js';
import '../styles/Settings.css';
import '../styles/dark-mode.css'

const { Title } = Typography;

// Add this constant for VAPID public key
const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

const Settings = () => {
  const [pushNotifications, setPushNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);

  const [showFollowers, setShowFollowers] = useState(false);
  const [showZodiacSign, setShowZodiacSign] = useState(false);
  const [showDaysToBirthday, setShowDaysToBirthday] = useState(false);
  
  const [uid, setUid] = useState(null);
  const [userProfile, setUserProfile] = useState(null); // Define userProfile state
  const [isSaving, setIsSaving] = useState(false);

  // Add new state for subscription
  const [pushSubscription, setPushSubscription] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUid(user.uid);
        loadSettings(user.uid);
        const profile = await getUserProfile(user.uid); // Fetch user profile
        setUserProfile(profile); // Set user profile state
      }
    });
  }, []);

  // Function to fetch user profile data
  const getUserProfile = async (uid) => {
    const db = getFirestore();
    const userProfileRef = doc(db, 'userProfiles', uid);
    const userProfileDoc = await getDoc(userProfileRef);
    if (userProfileDoc.exists()) {
      return userProfileDoc.data();
    } else {
      return null;
    }
  };

  const loadSettings = async (uid) => {
    const db = getFirestore();
    const settingsRef = doc(db, 'userSettings', uid);
    const settingsDoc = await getDoc(settingsRef);

    if (settingsDoc.exists()) {
      const settingsData = settingsDoc.data();
      setPushNotifications(settingsData.pushNotifications);
      setEmailNotifications(settingsData.emailNotifications);
      setShowFollowers(settingsData.showFollowers);
    }
  };

  const saveSettings = async () => {
    if (!uid) return;

    try {
      setIsSaving(true);
      const db = getFirestore();
      await setDoc(doc(db, 'userSettings', uid), {
        pushNotifications,
        emailNotifications,
        showFollowers,
        showZodiacSign,
        showDaysToBirthday
      });

      notification.success({
        message: 'Settings Updated',
        description: 'Your settings have been successfully updated.',
        duration: 4,
        placement: 'bottom',
        className: 'ant-notification-notice-dark'

      });
    } catch (error) {
      console.error('Error updating settings:', error);
      notification.error({
        message: 'Update Failed',
        description: 'There was a problem updating your settings.',
        duration: 4,
        placement: 'bottom',
        className: 'ant-notification-notice-dark'
      });
    } finally {
      setIsSaving(false);
    }
  };

  // Add this function to request notification permission and subscribe
  const subscribeToPushNotifications = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey
        });
        setPushSubscription(subscription);
        await manageUserSubscriptionInFirestore(subscription);
        return true;
      }
    } catch (error) {
      console.error('Error subscribing to push notifications:', error);
    }
    return false;
  };

  // Add this function to unsubscribe from push notifications
  const unsubscribeFromPushNotifications = async () => {
    if (pushSubscription) {
      try {
        await pushSubscription.unsubscribe();
        await manageUserSubscriptionInFirestore(null);
        setPushSubscription(null);
        return true;
      } catch (error) {
        console.error('Error unsubscribing from push notifications:', error);
        return false;
      }
    }
    return true; // Return true if there was no subscription to unsubscribe from
  };

  // Add this function to manage user subscription in Firestore
  const manageUserSubscriptionInFirestore = async (subscription) => {
    if (!uid) return;
    const db = getFirestore();
    const userSubscriptionRef = doc(db, 'userSubscriptions', uid);
    if (subscription) {
      await setDoc(userSubscriptionRef, { subscription: JSON.parse(JSON.stringify(subscription)) });
    } else {
      await setDoc(userSubscriptionRef, { subscription: null });
    }
  };

  // Modify the handlePushNotificationsChange function
  const handlePushNotificationsChange = async (checked) => {
    try {
      if (checked) {
        const subscribed = await subscribeToPushNotifications();
        if (subscribed) {
          setPushNotifications(true);
          notification.success({
            message: 'Push Notifications Enabled',
            description: 'You have successfully subscribed to push notifications.',
            duration: 4,
            placement: 'bottom',
            className: 'ant-notification-notice-dark'
          });
        } else {
          // If subscription failed, revert the switch
          setPushNotifications(false);
        }
      } else {
        const unsubscribed = await unsubscribeFromPushNotifications();
        if (unsubscribed) {
          setPushNotifications(false);
          notification.info({
            message: 'Push Notifications Disabled',
            description: 'You have unsubscribed from push notifications.',
            duration: 4,
            placement: 'bottom',
            className: 'ant-notification-notice-dark'
          });
        } else {
          // If unsubscription failed, revert the switch
          setPushNotifications(true);
        }
      }
    } catch (error) {
      console.error('Error changing push notification settings:', error);
      // Revert the switch to its previous state
      setPushNotifications(!checked);
      notification.error({
        message: 'Error',
        description: 'Failed to change push notification settings. Please try again.',
        duration: 4,
        placement: 'bottom',
        className: 'ant-notification-notice-dark'
      });
    }
  };

  return (
    <div>
      <Navbar userProfile={userProfile} />
      <div className="auth-container">
        <Card className="card-container">
          <Title level={3}>Settings</Title>
          <Form layout="vertical">
            <div className="form-item-inline">
              <label>Push Notifications</label>
              <Switch checked={pushNotifications} onChange={handlePushNotificationsChange} />
            </div>
  
            <div className="form-item-inline">
              <label>Email Notifications</label>
              <Switch checked={emailNotifications} onChange={setEmailNotifications} />
            </div>

            <Title level={3}>Public Profile</Title>
  
            <div className="form-item-inline">
              <label>Show Followers / Following</label>
              <Switch checked={showFollowers} onChange={setShowFollowers} />
            </div>

            <div className="form-item-inline">
              <label>Show Zodiac Sign</label>
              <Switch checked={showZodiacSign} onChange={setShowZodiacSign} />
            </div>

            <div className="form-item-inline">
              <label>Show Days to Birthday</label>
              <Switch checked={showDaysToBirthday} onChange={setShowDaysToBirthday} />
            </div>
  
            <Form.Item>
              <Button type="primary" onClick={saveSettings} loading={isSaving}>
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
  
};

export default Settings;
