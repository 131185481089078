// LandingPage.js
import React, { useEffect, useRef, useState } from 'react';
import { Button, Menu } from 'antd';
import './styles/LandingPage.css';
import { Link } from 'react-router-dom';
import logo from './assets/images/dayze-logo.svg';
import StarField from './components/StarField.js';
import { Helmet } from 'react-helmet';
import Sparkles from './components/Sparkles.js';
import LoadingScreen from './LoadingScreen.js';
import CurrentTime from './components/CurrentTime.js';
import SectionOne from './components/SectionOne.js';
import DayAgeCalculator from './components/DayAgeCalculator.js';
import FooterComponent from './components/FooterComponent.js';


const LandingPage = () => {
    const [sparkle, setSparkle] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const buttonRef = useRef();


    // Refs for your sections
    const sectionOneRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);  // Adjust the time as needed

        return () => clearTimeout(timer);  // Cleanup the timer
    }, []);

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        metaThemeColor.setAttribute("content", "#000");
        return () => {
            metaThemeColor.setAttribute("content", "#202020");
        };
    }, []);

    const handleButtonClick = () => {
        setSparkle(true);
        setTimeout(() => {
            setSparkle(false);
        }, 800);
    };

    return (
        <>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <div className="dark-mode">
                    <Helmet>  {/* Add Helmet component to update meta title and description */}
                        <title>Dayze - Unleash the Magic of Every Day</title>
                        <meta name="description" content="Step into the world of Dayze, where every moment is a masterpiece, every experience a journey of discovery. Unleash the magic of every day with Dayze's personalized planner, social network, and digital memory keeper." />
                    </Helmet>
                    <div className="starfield-container">
                        <StarField />
                    </div>

                    <Menu mode="horizontal" theme="dark" className="navbar-landing">
                        <div className="left-menu">
                            <Menu.Item key="logo" className="logo-item">
                                <img src={logo} alt="Dayze" className="logo" />
                                <span className="logo-text">dayze <span className="beta-tag">alpha</span></span>
                            </Menu.Item>
                        </div>
                        <div className="middle-menu">
                            {/* <Menu.Item key="features">
                        Features
                    </Menu.Item>
                    <Menu.Item key="pricing">
                        Pricing
                    </Menu.Item>
                    <Menu.Item key="careers">
                        Careers
                    </Menu.Item> */}
                        </div>
                        <div className="right-menu">
                            <Menu.Item key="login">
                                <Link className='login-link' to="/login">Login</Link>
                            </Menu.Item>
                            <Menu.Item key="signup">
                                <Link className='signup-btn' to="/signup">
                                    <Button type="primary">
                                        Sign Up
                                    </Button>
                                </Link>
                            </Menu.Item>
                        </div>
                    </Menu>

                    {/* Content */}

                    <div className="content">
                        <CurrentTime />
                        <h1 className="title">Experience<br />Life in Dayze</h1>
                        <p className="description">
                            Dayze isn't just another app—it's your life, amplified. Fuse planning precision with social spontaneity and memory magic. Transform mundane moments into epic adventures. Organize less, live more. With Dayze, every day is a story waiting to be written, shared, and remembered. Don't just plan your life—ignite it.
                        </p>
                        <Link to="/signup">
                            <Sparkles>
                                <Button
                                    ref={buttonRef}  // Attach the ref to the button
                                    className="cta-btn"
                                    type="primary"
                                    size="large"
                                    onClick={handleButtonClick}
                                    style={{ position: 'relative', zIndex: sparkle ? 2 : 1 }}
                                >
                                    Try Dayze for free
                                </Button>
                            </Sparkles>
                        </Link>
                        <DayAgeCalculator />
                        {/* Additional Sections */}
                        <div ref={sectionOneRef}>
                            <SectionOne />
                        </div>
                        <FooterComponent />


                    </div>
                </div>
            )}
        </>
    );
}

export default LandingPage;