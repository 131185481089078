// LoadingScreen.js
import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';
import LoaderIcon from './assets/images/dayze-loader.svg'; 

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const createOnLoadHandler = (images, resolve) => {
      let loadedImagesCount = 0;
      return () => {
        loadedImagesCount++;
        const progress = Math.floor((loadedImagesCount / images.length) * 100);
        setProgress(progress);

        if (loadedImagesCount === images.length) {
          resolve();
        }
      };
    };

    const loadImages = new Promise((resolve) => {
      const images = document.images;
      const onLoad = createOnLoadHandler(images, resolve);

      for (let i = 0; i < images.length; i++) {
        const img = new Image();
        img.onload = img.onerror = onLoad;
        img.src = images[i].src;
      }
    });

    const loadComplete = () => {
      setProgress(100);
    };

    Promise.all([loadImages]).then(loadComplete);

    return () => {
      window.onload = null;
    };
  }, []);

  return (
    <div className="loading-screen">
      <div className="loader-container">
        <img src={LoaderIcon} alt="Loading..." className="loading-icon" />
        <div className="loader-bar">
          <div 
            className="loader-bar-progress" 
            style={{ width: `${progress}%` }}
          >
            {progress}% Loaded
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
