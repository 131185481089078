// LoginPage.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { auth, db } from './firebaseConfig.js';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Card } from 'antd';
import { Helmet } from 'react-helmet';
import ThemeSwitcher from './ThemeSwitcher.js';
import StarField from './components/StarField.js';
// import { Spin } from 'antd';

import './styles/AuthPage.css';
import { ReactComponent as BrandLogo } from './assets/images/dayze-logo-full.svg';

const LoginPage = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const signInWithEmailAndPasswordHandler = async (event, usernameOrEmail, password) => {
    event.preventDefault();
    setIsLoading(true);
    let email = usernameOrEmail;

    if (!usernameOrEmail.includes('@')) {
      try {
        const userProfilesRef = collection(db, 'userProfiles');
        const q = query(userProfilesRef, where('handle', '==', usernameOrEmail));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setError('Error signing in with username and password');
          setIsLoading(false);
          return;
        }
        email = querySnapshot.docs[0].data().email;
      } catch (error) {
        setError('Error fetching user email');
        console.error('Error fetching user email: ', error);
        setIsLoading(false); 
        return;
      }
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      setError('Error signing in with password and email!');
      console.error('Error signing in with password and email: ', error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Dayze - Log In</title>
        <meta name="description" content="Log in to Dayze and experience life in days." />
        <meta name="keywords" content="Dayze, day calculator, dayze me, log in, life in days" />
      </Helmet>
      <div className="starfield-container">
                        <StarField />
                    </div>
      <ThemeSwitcher />
      <Link to="/">
        <BrandLogo className="BrandLogo" />
      </Link>
      <h1 className="login-title">Experience life in days.</h1>
      <div className="auth-container">
        <Card className="card-container-login">
          <div>
            {error !== null && <div>{error}</div>}
            <Form
              className="no-auto-zoom"
              onFinish={(event) =>
                signInWithEmailAndPasswordHandler(event, usernameOrEmail, password)
              }
            >
              <div className="form-group">
              <Input
                className="form-input"
                type="text"
                value={usernameOrEmail}
                onChange={(event) => setUsernameOrEmail(event.target.value.toLowerCase())}
                placeholder=' '
              />
              <label className="form-label">Email or Username</label>
            </div>

              <Form.Item>
                <Input.Password
                // className="form-input"
                style={{ height: '50px', borderRadius: '12px' }}
                  value={password}
                  placeholder="Password"
                  onChange={(event) => setPassword(event.target.value)}
                />
                {/* <label className="form-label">Password</label> */}
              </Form.Item>
              
              <Link to="/forgot-password">Forgot Password?</Link>
              <Form.Item>
                <Button className='login-button'
                  type="primary"
                  style={{ height: '50px', width: '100%', marginTop: '14px', borderRadius: '12px' }}
                  onClick={(event) =>
                    signInWithEmailAndPasswordHandler(event, usernameOrEmail, password)
                  }
                  htmlType="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <strong>Logging in</strong> : <strong>Login</strong>}
                </Button>
              </Form.Item>
            </Form>

            <p>
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
