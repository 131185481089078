import { useEffect, useState } from 'react';

const ThemeSwitcher = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setIsDarkMode(storedTheme === 'dark');
      return;
    }

    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(prefersDarkMode.matches);

    const handleThemeChange = (e) => {
      setIsDarkMode(e.matches);
    };

    prefersDarkMode.addEventListener('change', handleThemeChange);

    return () => {
      prefersDarkMode.removeEventListener('change', handleThemeChange);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      import('./styles/dark-mode.css');
      localStorage.setItem('theme', 'dark');
    } else {
      import('./AuthPage.css');
      localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  return null;
};

export default ThemeSwitcher;
