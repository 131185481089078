import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import PeopleListItem from './PeopleListItem.js';
import useUpdatePeopleCount from './hooks/useUpdatePeopleCount.js';
import './styles/YourPeople.css';

const YourPeople = ({ viewAsUserId }) => {
    const [people, setPeople] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // Added a new state for the search term
    const auth = getAuth();
    const uid = viewAsUserId || auth.currentUser?.uid; // use viewAsUserId if it exists

    useUpdatePeopleCount(uid);

    useEffect(() => {
        if (uid) {  // Ensure UID is available
            const db = getFirestore();
            const peopleCollection = collection(db, 'people');
            const userPeopleQuery = query(peopleCollection, where('uid', '==', uid));

            const unsubscribe = onSnapshot(userPeopleQuery, (querySnapshot) => {
                const peopleList = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setPeople(peopleList);
            });

            return () => {
                unsubscribe();
            };
        }
    }, [uid]);

    const handlePersonSave = (updatedPerson) => {
        const updatedPeople = people.map((person) => {
            if (person.id === updatedPerson.id) {
                return updatedPerson;
            }
            return person;
        });
        setPeople(updatedPeople);
    };

    const filteredPeople = people.filter(person => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        return (
            person.name.toLowerCase().includes(lowercasedSearchTerm) ||
            (person.occupation && person.occupation.toLowerCase().includes(lowercasedSearchTerm)) ||
            (person.notes && person.notes.toLowerCase().includes(lowercasedSearchTerm)) ||
            (person.gender && person.gender.toLowerCase().includes(lowercasedSearchTerm))
        );
    });

    // Calculate the placeholder text based on the number of people
    const placeholderText = `Search ${people.length === 1 ? '1 Person' : `${people.length} People`}`;

    return (
        <div>
            <div className="search-container">
                <input
                    type="text"
                    placeholder={placeholderText}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
                {searchTerm && (
                    <button onClick={() => setSearchTerm('')} className="clear-button">x</button>
                )}
            </div>

            {filteredPeople.map((person) => (
                <PeopleListItem
                    key={person.id}
                    person={person}
                    onPersonSave={handlePersonSave}
                />
            ))}
        </div>
    );
};

export default YourPeople;




