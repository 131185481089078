import { useEffect, useRef } from 'react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const UserActivityTracker = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  const userActivityRef = useRef({
    lastSeen: Date.now(),
    sessionTime: 0,
    intervalId: null,
  });

  const handleVisibilityChange = () => {
    if (document.hidden) {
      clearInterval(userActivityRef.current.intervalId);
    } else {
      userActivityRef.current.intervalId = setInterval(() => {
        userActivityRef.current.sessionTime += 1;
      }, 1000);
    }
  };

  useEffect(() => {
    if (user) {
      const updateUserActivity = async () => {
        const db = getFirestore();
        const userDocRef = doc(db, 'userProfiles', user.uid);

        await updateDoc(userDocRef, {
          lastSeen: userActivityRef.current.lastSeen,
          sessionTime: userActivityRef.current.sessionTime,
        });
      };

      window.addEventListener('visibilitychange', handleVisibilityChange);
      userActivityRef.current.intervalId = setInterval(() => {
        userActivityRef.current.sessionTime += 1;
      }, 1000);

      // Set an interval to call updateUserActivity periodically
      const updateActivityInterval = setInterval(() => {
        updateUserActivity();
      }, 30 * 1000); // Update every 30 seconds

      const currentRef = userActivityRef.current;

      return () => {
        clearInterval(currentRef.intervalId);
        clearInterval(updateActivityInterval); // Clear the interval on cleanup
        window.removeEventListener('visibilitychange', handleVisibilityChange);
        updateUserActivity();
      };
    }
  }, [user]);

  return null;
};

export default UserActivityTracker;
