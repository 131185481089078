import React, { useState } from 'react';
import { auth } from './firebaseConfig.js';
import EditPersonModal from './EditPersonModal.js';
import defaultAvatar from './assets/images/default-avatar.svg';

const PeopleListItem = ({ person, onPersonSave }) => {
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isLightboxVisible, setIsLightboxVisible] = useState(false);
    const [people, setPeople] = useState([]); 

    const handlePersonSave = (updatedPerson) => {
        const updatedPeople = people.map((person) => {
            if (person.id === updatedPerson.id) {
                return updatedPerson;
            }
            return person;
        });
        setPeople(updatedPeople);
    };

    return (
        <div>

            <div className="people-item" onClick={() => setIsLightboxVisible(true)}>
                <div className="left-column">
                    <img className="people-image" src={person.imageUrl || defaultAvatar} alt={`${person.name}`} />
                </div>
                <div className="right-column">
                    <p className="people-name" onClick={(e) => { e.stopPropagation(); setIsEditModalVisible(true); }}>
                        {person.name}
                    </p>
                </div>
            </div>

            {isLightboxVisible && (
                <div className="modal" onClick={() => setIsLightboxVisible(false)}>
                    <img
                        src={person.imageUrl || defaultAvatar}
                        alt={`${person.name}`}
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                </div>
            )}

            <EditPersonModal
                uid={auth.currentUser ? auth.currentUser.uid : null}
                person={person}
                open={isEditModalVisible}
                handleCloseEditModal={() => setIsEditModalVisible(false)}
                onPersonSave={handlePersonSave}
            />
        </div>
    );
};

export default PeopleListItem;