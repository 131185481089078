import React, { useState } from 'react';
import EventListItem from './EventListItem.js';
import useUpdateEventCount from './hooks/useUpdateEventCount.js';
import './styles/YourEvents.css';

const YourEvents = ({ events, onDelete, onEdit, now, uid }) => {
  const [searchTerm, setSearchTerm] = useState('');

  useUpdateEventCount(uid, events);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const initialToday = new Date(now);
  initialToday.setHours(0, 0, 0, 0);
  const initialTomorrow = new Date(initialToday);
  initialTomorrow.setDate(initialToday.getDate() + 1);
  const initialYesterday = new Date(initialToday);
  initialYesterday.setDate(initialToday.getDate() - 1);

  const filteredEvents = events.filter(event => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const eventDate = new Date(event.date.toDate());
    const eventDateStart = new Date(eventDate);
    eventDateStart.setHours(0, 0, 0, 0);
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);
    const in7days = new Date(today);
    in7days.setDate(today.getDate() + 7);
    const in30days = new Date(today);
    in30days.setDate(today.getDate() + 30);

    const isEventDateMatchingSearchTerm = (
      (lowercasedSearchTerm.startsWith('today') && eventDateStart.getTime() === today.getTime()) ||
      (lowercasedSearchTerm.startsWith('tomorrow') && eventDateStart.getTime() === initialTomorrow.getTime()) ||
      (lowercasedSearchTerm.startsWith('yesterday') && eventDateStart.getTime() === initialYesterday.getTime()) ||
      (lowercasedSearchTerm.startsWith('in 7 days') && eventDateStart.getTime() >= today.getTime() && eventDateStart.getTime() < in7days.getTime()) ||
      (lowercasedSearchTerm.startsWith('in 30 days') && eventDateStart.getTime() >= today.getTime() && eventDateStart.getTime() < in30days.getTime()) ||
      (months.some(month => lowercasedSearchTerm.startsWith(month.toLowerCase())) &&
        eventDate.getMonth() === months.findIndex(month => lowercasedSearchTerm.startsWith(month.toLowerCase())))
    );

    return (
      event.title.toLowerCase().includes(lowercasedSearchTerm) ||
      (event.description && event.description.toLowerCase().includes(lowercasedSearchTerm)) ||
      (event.location && event.location.toLowerCase().includes(lowercasedSearchTerm)) ||
      isEventDateMatchingSearchTerm
    );
  });

  const handleEdit = (event) => {
    onEdit(event);
  };

  const getMonthName = (monthNumber) => {
    return months[monthNumber - 1];
  };

  const getEventGroups = (eventsToGroup) => {
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const in7days = new Date(today);
    in7days.setDate(today.getDate() + 7);
    const in30days = new Date(today);
    in30days.setDate(today.getDate() + 30);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setDate(today.getDate() - 30);

    const groups = eventsToGroup.reduce(
      (accumulator, event) => {
        const eventDate = new Date(event.date.toDate());
        const eventDateStart = new Date(eventDate);
        eventDateStart.setHours(0, 0, 0, 0);

        if (eventDate >= in30days) {
          const monthYear = `Future-${eventDate.getMonth() + 1}/${eventDate.getFullYear()}`;
          if (!accumulator[monthYear]) {
            accumulator[monthYear] = [];
          }
          accumulator[monthYear].push(event);
        } else if (eventDate >= in7days) {
          accumulator.upcoming30days.push(event);
        } else if (eventDate >= tomorrow && eventDateStart.getTime() !== tomorrow.getTime()) {
          accumulator.upcoming7days.push(event);
        } else if (eventDateStart.getTime() === tomorrow.getTime()) {
          accumulator.tomorrow.push(event);
        } else if (eventDateStart.getTime() === today.getTime()) {
          accumulator.today.push(event);
        } else if (eventDateStart.getTime() === yesterday.getTime()) {
          accumulator.yesterday.push(event);
        } else if (eventDate > oneWeekAgo && eventDate <= yesterday) {
          accumulator.last7days.push(event);
        } else if (eventDate <= oneWeekAgo && eventDate > oneMonthAgo) {
          accumulator.last30days.push(event);
        } else {
          const monthYear = `${eventDate.getMonth() + 1}/${eventDate.getFullYear()}`;
          if (!accumulator[monthYear]) {
            accumulator[monthYear] = [];
          }
          accumulator[monthYear].push(event);
        }
        return accumulator;
      },
      {
        upcoming7days: [],
        upcoming30days: [],
        tomorrow: [],
        today: [],
        yesterday: [],
        last7days: [],
        last30days: []
      }
    );

    const sortEvents = (a, b) => new Date(b.date.toDate()) - new Date(a.date.toDate());
    Object.values(groups).forEach(group => group.sort(sortEvents));

    // Function to clear the search input


    return groups;
  };

  const {
    upcoming30days = [],
    upcoming7days = [],
    tomorrow = [],
    today = [],
    yesterday = [],
    last7days = [],
    last30days = [],
    ...otherGroups
  } = getEventGroups(filteredEvents);


  const placeholderText = `Search ${events.length === 1 ? '1 Event' : `${events.length} Events`}`;

  return (
    <div>
      <div className="search-container">
        <input
          type="text"
          placeholder={placeholderText}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        {searchTerm && (
          <button onClick={() => setSearchTerm('')} className="clear-button">x</button>
        )}
      </div>
      {[
        ...Object.entries(otherGroups).filter(([key]) => key.startsWith('Future')).map(([key, value]) => {
          const [, monthYear] = key.split('-');
          const [month, year] = monthYear.split('/').map(Number);
          const monthName = getMonthName(month);
          return [`${monthName} ${year}`, value];
        }).sort(([aKey], [bKey]) => {
          const bDateParts = bKey.split(' ');
          const bMonth = months.indexOf(bDateParts[0]) + 1;
          const bYear = Number(bDateParts[1]);
          const aDateParts = aKey.split(' ');
          const aMonth = months.indexOf(aDateParts[0]) + 1;
          const aYear = Number(aDateParts[1]);
          return new Date(bYear, bMonth - 1) - new Date(aYear, aMonth - 1);
        }),
        ...Object.entries({
          'In 30 Days': upcoming30days,
          'In 7 Days': upcoming7days,
          Tomorrow: tomorrow,
          Today: today,
          Yesterday: yesterday,
          'Last 7 Days': last7days,
          'Last 30 Days': last30days,
        }),
        ...Object.entries(otherGroups).filter(([key]) => !key.startsWith('Future')).map(([key, value]) => {
          const [month, year] = key.split('/').map(Number);
          const monthName = getMonthName(month);
          return [`${monthName} ${year}`, value];
        }).sort(([aKey], [bKey]) => {
          const bDateParts = bKey.split(' ');
          const bMonth = months.indexOf(bDateParts[0]) + 1;
          const bYear = Number(bDateParts[1]);
          const aDateParts = aKey.split(' ');
          const aMonth = months.indexOf(aDateParts[0]) + 1;
          const aYear = Number(aDateParts[1]);
          return new Date(bYear, bMonth - 1) - new Date(aYear, aMonth - 1);
        })
      ].map(([heading, events]) => {
        if (events.length > 0) {
          return (
            <div key={heading}>
              <h3 className="event-header">{heading}</h3>
              <ul className="event-list">
                {events.map((event, index) => (
                  <EventListItem
                    key={index}
                    event={event}
                    now={now}
                    onDelete={onDelete}
                    onEdit={handleEdit}
                    onEventSave={onEdit}
                  />
                ))}
              </ul>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default YourEvents;