import React, { useState } from 'react';
import { Button, Modal, Input, Form, Typography, Select } from 'antd';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import defaultAvatar from './assets/images/default-avatar.svg';
import useUpdatePeopleCount from './hooks/useUpdatePeopleCount.js';
import './styles/PersonModal.css';

const { Text } = Typography;
const { Option } = Select;

const PersonModal = ({ uid }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [name, setName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(defaultAvatar);
    const [successMessage, setSuccessMessage] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [firstMet, setFirstMet] = useState('');
    const [notes, setNotes] = useState('');
    const [firstMetLocation, setFirstMetLocation] = useState('');
    const [address, setAddress] = useState(''); // New state for address
    const [occupation, setOccupation] = useState(''); // New state for occupation
    const [gender, setGender] = useState(''); // New state for gender

    useUpdatePeopleCount(uid);

    const showModal = () => {
        setIsModalVisible(true);
        setSuccessMessage(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSuccessMessage(false);
    };

    const handleOk = async () => {
        setIsSaving(true);
        try {
            const db = getFirestore();
            const peopleRef = collection(db, 'people');
            const newPersonRef = doc(peopleRef);
            const newPersonData = {
                id: newPersonRef.id,
                name,
                gender, // Add gender to the person data
                birthday,
                firstMet: firstMet,
                firstMetLocation,
                notes,
                imageUrl: '',
                address, // New address field
                occupation, // New occupation field
                uid: uid
            };
            if (profileImage) {
                const profileImageUrl = await uploadProfileImage(newPersonRef.id, profileImage);
                newPersonData.imageUrl = profileImageUrl;
            }
            await setDoc(newPersonRef, newPersonData);
            setSuccessMessage(true);
            setName('');
            setBirthday('');
            setProfileImage(null);
            setDisplayImage(defaultAvatar);
            setFirstMet('');
            setNotes('');
            setIsSaving(false);
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error adding new person to Firestore:', error);
            setIsSaving(false);
        }
    };

    const uploadProfileImage = async (personId, imageFile) => {
        const storage = getStorage();
        const storageRef = ref(storage, `profileImages/${personId}`);
        await uploadBytes(storageRef, imageFile);
        return getDownloadURL(storageRef);
    };

    return (
        <div>
            <Button type="primary" onClick={showModal}>
                Add Person
            </Button>
            <Modal className='event-modal'
                title="Add Person"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                        icon={isSaving ? <LoadingOutlined /> : <CheckCircleOutlined />}
                        disabled={isSaving || name.trim() === ''}
                    >
                        Save
                    </Button>,

                ]}
            >
                {successMessage && <Text type="success">Person added!</Text>}
                <Form>
                    <Form.Item label="Profile Photo">
                        <img
                            src={displayImage}
                            alt="Profile"
                            width="100"
                            height="100"
                            style={{ marginBottom: '1rem', borderRadius: '50%' }}
                        />
                        <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                setProfileImage(e.target.files[0]);
                                setDisplayImage(URL.createObjectURL(e.target.files[0]));
                            }}
                        />
                    </Form.Item>

                    <Input
                        className="form-input"
                        type="text"
                        value={name}
                        placeholder=" "
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label className="form-label">Name</label>

                    <Form.Item label="Gender">
                        <Select
                            value={gender}
                            onChange={(value) => setGender(value)}
                            placeholder="Select gender"
                        >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Trans">Trans</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Input
                            className="form-input"
                            type="datetime-local"
                            value={firstMet}
                            onChange={(e) => setFirstMet(e.target.value)}
                        />
                        <label className="form-label"> First Met</label>
                    </Form.Item>


                    <Input
                        className="form-input"
                        type="text"
                        value={firstMetLocation}
                        placeholder=" "
                        onChange={(e) => setFirstMetLocation(e.target.value)}
                    />
                    <label className="form-label">  First Met Location</label>


                    <Form.Item>
                        <Input
                            className="form-input"
                            type="date"
                            value={birthday}
                            placeholder=" "
                            onChange={(e) => setBirthday(e.target.value)}
                        />
                        <label className="form-label">Birthdate</label>
                    </Form.Item>

                    <Input
                        className="form-input"
                        type="text"
                        value={address}
                        placeholder=" "
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="form-label">Address</label>

                    <Input
                        className="form-input"
                        type="text"
                        value={occupation}
                        placeholder=" "
                        onChange={(e) => setOccupation(e.target.value)}
                    />
                    <label className="form-label">Occupation</label>

                    <Form.Item className="form-item">
                        <Input.TextArea
                            className="form-input-textarea"
                            value={notes}
                            placeholder=" "
                            onChange={(e) => setNotes(e.target.value)}
                            autoSize={{ minRows: 3, maxRows: 10 }}
                        />
                        <label className="form-label-textarea">Notes</label>
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default PersonModal;