import React, { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, collection, query as createQuery, where, deleteDoc, onSnapshot } from 'firebase/firestore';
import { auth } from './firebaseConfig.js';
import { Link } from 'react-router-dom';
import { Card, Image } from 'antd';
import EventModal from './EventModal.js';
import YourEvents from './YourEvents.js';
import PersonModal from './PersonModal.js';
import ThemeSwitcher from './ThemeSwitcher.js';
import SunSign from './SunSign.js';
import UserActivityTracker from './UserActivityTracker.js';
import PolarisChat from './components/PolarisChat/PolarisChat.js';
import LoadingScreen from './LoadingScreen.js';
import './LoadingScreen.css';
import Navbar from './Navbar.js';
import { calculateDayAge } from './dayAge.js';
import { daysToNextBirthday } from './daysToNextBirthday.js';
import { countdownToNextDay } from './countdown.js';
import defaultAvatar from './assets/images/default-avatar.svg';
import { useLocation } from 'react-router-dom';
import YourPeople from './YourPeople.js';
import DarkModeCalendarIcon from './assets/icons/DarkModeCalendarIcon.svg';
import LightModeCalendarIcon from './assets/icons/LightModeCalendarIcon.svg';
import DarkModePeopleIcon from './assets/icons/DarkModePeopleIcon.svg';
import LightModePeopleIcon from './assets/icons/LightModePeopleIcon.svg';
import RandomEmoji from './components/RandomEmoji.js';
import FollowingModal from './components/FollowingModal.js';
import FollowersModal from './components/FollowersModal.js';


const HomePage = () => {
    const [greeting, setGreeting] = useState("");
    const [email, setEmail] = useState("");
    const [userProfile, setUserProfile] = useState(null);
    const defaultAvatarUrl = defaultAvatar;
    const [events, setEvents] = useState([]);
    const [now, setNow] = useState(new Date());
    const profileRef = useRef(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const viewAsUserId = location.state?.viewAsUserId;
    const [activeView, setActiveView] = useState('calendar');
    const [isFollowingModalVisible, setFollowingModalVisible] = useState(false);
    const [isFollowersModalVisible, setFollowersModalVisible] = useState(false);

    const handleViewSwitch = (view) => {
        setActiveView(view);
    }

    const getEvents = async (uid) => {
        try {
            const db = getFirestore();
            const eventsRef = collection(db, 'events');
            const userEventsQuery = createQuery(eventsRef, where("uid", "==", uid));
            const unsubscribe = onSnapshot(userEventsQuery, (querySnapshot) => {
                const eventList = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setEvents(eventList);
            });
            return unsubscribe;
        } catch (error) {
            console.error('Error fetching events: ', error);
        }
    };

    const handleEditEvent = (event) => {
        console.log('Editing event:', event);
    };

    useEffect(() => {
        const auth = getAuth();

        const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setEmail(user.email);

                const uid = viewAsUserId || user.uid;
                const userProfile = await getUserProfile(uid);
                setUserProfile(userProfile);

                if (userProfile) {
                    document.title = `${userProfile.name} (@${userProfile.handle}) • Dayze`;
                } else {
                    document.title = "Dayze - Experience Life in Days";
                }

                const unsubscribeEvents = await getEvents(uid);
                setIsLoading(false);

                // Return the cleanup function for the events subscription
                return () => {
                    unsubscribeEvents();
                };
            } else {
                // Redirect to login page or other handling
            }
        });

        const currentHour = new Date().getHours();
        let currentGreeting;
        if (currentHour < 12) {
            currentGreeting = "Good morning";
        } else if (currentHour < 18) {
            currentGreeting = "Good afternoon";
        } else {
            currentGreeting = "Good evening";
        }
        setGreeting(currentGreeting);

        const nowInterval = setInterval(() => {
            setNow(new Date());
        }, 1000);

        return () => {
            clearInterval(nowInterval);
            unsubscribeAuth();
        };
    }, [viewAsUserId]);


    const getUserProfile = async (uid) => {
        const db = getFirestore();
        const userProfileRef = doc(db, 'userProfiles', uid);
        const userProfileDoc = await getDoc(userProfileRef);
        if (userProfileDoc.exists()) {
            const userProfileData = userProfileDoc.data();
            return {
                ...userProfileData,
                isSuperUser: userProfileData.isSuperUser || false
            };
        } else {
            return null;
        }
    };


    const handleEventSave = (event) => {
        setEvents((prevEvents) => [...prevEvents, event]);
    };

    const handleDeleteEvent = async (eventId) => {
        try {
            const db = getFirestore();
            const eventRef = doc(db, 'events', eventId);
            await deleteDoc(eventRef);
            setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId));
        } catch (error) {
            console.error('Error deleting event: ', error);
        }
    };

    return (
        <>
            <ThemeSwitcher />
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    <UserActivityTracker />
                    <div>
                        <Navbar userProfile={userProfile} />
                        <div className="auth-container">
                            {userProfile && userProfile.birthday ? (
                                <>
                                    <p className="day-counter">
                                        day<br /><strong>{calculateDayAge(new Date(userProfile.birthday))}</strong>
                                    </p>
                                </>
                            ) : (
                                <p>
                                    <Link className="no-day-age" to="/edit-profile">Enter your birthday to reveal day age!</Link>
                                </p>
                            )}
                            <Card className="card-container">
                                <div style={{ textAlign: 'center' }}>
                                    <div>
                                        <div style={{ position: 'relative' }}>
                                            {userProfile && userProfile.profileImageUrl ? (
                                                <Image
                                                    src={userProfile.profileImageUrl}
                                                    alt="Profile"
                                                    style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'cover' }}
                                                    fallback={defaultAvatarUrl}
                                                    preview={{
                                                        mask: '', // Remove the darkening effect
                                                        visible: isModalVisible,
                                                        onVisibleChange: (visible) => setModalVisible(visible),
                                                        getContainer: () => document.body,
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    src={defaultAvatarUrl}
                                                    alt="Profile"
                                                    style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'cover' }}
                                                    ref={profileRef}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <Link
                                        to={userProfile && userProfile.handle ? `/${userProfile.handle}` : "/edit-profile"}
                                        className="gradient-link"
                                        style={{ display: 'block', marginTop: '0px', fontSize: '16px' }}
                                    >
                                        {userProfile && userProfile.handle ? `@${userProfile.handle}` : "Claim your username!"}
                                    </Link>
                                    <SunSign birthday={userProfile && userProfile.birthday ? new Date(userProfile.birthday) : null} />

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-20px' }}>
                                        <div className="followers-container" onClick={() => setFollowersModalVisible(true)}>
                                            <p className="following-followers-count">{userProfile && userProfile.followers ? userProfile.followers.length : 0}</p>
                                            <p className="following-followers-title">{userProfile && userProfile.followers && userProfile.followers.length === 1 ? 'Follower' : 'Followers'}</p>
                                        </div>
                                        <div className="following-container" onClick={() => setFollowingModalVisible(true)}>
                                            <p className="following-followers-count">{userProfile && userProfile.following ? userProfile.following.length : 0}</p>
                                            <p className="following-followers-title">Following</p>
                                        </div>

                                        <FollowersModal
                                            uid={userProfile?.uid}
                                            isModalVisible={isFollowersModalVisible}
                                            setIsModalVisible={setFollowersModalVisible}
                                        />
                                        <FollowingModal
                                            uid={userProfile?.uid}
                                            isModalVisible={isFollowingModalVisible}
                                            setIsModalVisible={setFollowingModalVisible}
                                        />
                                    </div>

                                </div>
                                <h3>
                                    {greeting}, {userProfile && userProfile.name ? userProfile.name : email} <RandomEmoji />
                                </h3>

                                {userProfile && userProfile.birthday ? (
                                    <>
                                        <p>Days to Birthday: {daysToNextBirthday(new Date(userProfile.birthday))}</p>
                                        <p>Time to Next Day: {countdownToNextDay()}</p>
                                    </>
                                ) : null}

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px', marginBottom: '22px' }}>
                                    <div style={{ marginRight: '28px' }}>
                                        <EventModal uid={auth.currentUser ? auth.currentUser.uid : null} onEventSave={handleEventSave} />
                                    </div>
                                        <PersonModal uid={auth.currentUser ? auth.currentUser.uid : null} onEventSave={handleEventSave} />
                                    </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '-42px' }}>
                                    <div onClick={() => handleViewSwitch('calendar')} style={{ cursor: 'pointer', marginRight: '40px' }}>
                                        <img src={activeView === 'calendar' ? DarkModeCalendarIcon : LightModeCalendarIcon} alt="Calendar" style={{ marginBottom: '-5px' }} />
                                        {activeView === 'calendar' && <div style={{ height: '2px', backgroundColor: 'white', marginTop: '5px' }}></div>}
                                    </div>
                                    <div onClick={() => handleViewSwitch('people')} style={{ cursor: 'pointer' }}>
                                        <img src={activeView === 'people' ? DarkModePeopleIcon : LightModePeopleIcon} alt="People" style={{ marginBottom: '-5px' }} />
                                        {activeView === 'people' && <div style={{ height: '2px', backgroundColor: 'white', marginTop: '5px' }}></div>}
                                    </div>
                                </div>
                            </Card>
                            {activeView === 'calendar' && events.length > 0 && (
                                <YourEvents
                                    events={events}
                                    onDelete={handleDeleteEvent}
                                    onEdit={handleEditEvent}
                                    now={now}
                                    uid={auth.currentUser ? auth.currentUser.uid : null}
                                />
                            )}

                            {activeView === 'people' && (
                                <YourPeople
                                    viewAsUserId={viewAsUserId}
                                />
                            )}
                        </div>
                        {userProfile && userProfile.polaris && (
                            <>
                                <PolarisChat userProfile={userProfile} events={events} />
                            </>
                        )}

                    </div>
                </>
            )}
        </>
    );

};

export default HomePage;