import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { random } from '../utils/random.js';
import { range } from '../utils/range.js';
import { usePrefersReducedMotion } from '../hooks/usePrefersReducedMotion.js';
import { useRandomInterval } from '../hooks/useRandomInterval.js';

const DEFAULT_COLOR = '#FFC700';

const generateSparkle = color => {
    const sparkle = {
        id: String(random(10000, 99999)),
        createdAt: Date.now(),
        color,
        size: random(10, 20),
        style: {
            top: random(0, 100) + '%',
            left: random(0, 100) + '%',
        },
    };
    return sparkle;
};

const Sparkles = ({ color = DEFAULT_COLOR, children, ...delegated }) => {
    const [sparkles, setSparkles] = useState(() => {
        return range(3).map(() => generateSparkle(color));
    });

    const prefersReducedMotion = usePrefersReducedMotion();

    useRandomInterval(
      () => {
          const sparkle = generateSparkle(color);
          const now = Date.now();
          const nextSparkles = sparkles.filter(sp => {
              const delta = now - sp.createdAt;
              return delta < 750;
          });
          nextSparkles.push(sparkle);
          setSparkles(nextSparkles);
      },
      prefersReducedMotion ? null : 100,  // increased from 50
      prefersReducedMotion ? null : 900   // increased from 450
  );
  

    return (
        <Wrapper {...delegated}>
            {sparkles.map(sparkle => (
                <Sparkle
                    key={sparkle.id}
                    color={sparkle.color}
                    size={sparkle.size}
                    style={sparkle.style}
                />
            ))}
            <ChildWrapper>{children}</ChildWrapper>
        </Wrapper>
    );
};

const Sparkle = ({ size, color, style }) => {
    return (
        <SparkleWrapper style={style}>
            <SparkleSvg width={size} height={size} viewBox="0 0 121 121" fill="none">
                <circle cx="60.5874" cy="60.1748" r="60" fill={color} />
                <circle cx="60.5874" cy="60.1745" r="42.8571" fill="#DC9E22" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M61.834 80.1742C61.834 68.1626 72.7885 58.4252 86.3016 58.4252V34.46C57.8984 34.46 34.873 54.9269 34.873 80.1742H61.834Z"
                    fill={color}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M61.834 80.1742C61.834 68.1626 72.7885 58.4252 86.3016 58.4252V34.46C57.8984 34.46 34.873 54.9269 34.873 80.1742H61.834Z"
                    fill={color}
                />
            </SparkleSvg>
        </SparkleWrapper>
    );
};



// Enhanced springOut animation
const springOut = keyframes`
  0% {
    transform: scale(0) translateY(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1) translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
`;

// Enhanced bounce animation
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  
  50% {
    transform: translateY(-15px);
  }
`;

// Enhanced spin animation
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Wrapper = styled.span`
  display: inline-block;
  position: relative;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;



const SparkleWrapper = styled.span`
  position: absolute;
  display: block;
  opacity: 1;  // Ensure the initial opacity is set to 1
  @media (prefers-reduced-motion: no-preference) {
    animation: 
     ${fadeIn} 100ms forwards,
      ${springOut} 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, 
      ${bounce} 1.5s ease-in-out infinite,
      ${fadeOut} 500ms 650ms forwards;  // Apply fadeOut after 750ms delay
  }
`;

const SparkleSvg = styled.svg`
  display: block;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${spin} 1000ms linear;
  }
`;

const ChildWrapper = styled.strong`
  position: relative;
  z-index: 1;
  font-weight: bold;
`;

export default Sparkles;
