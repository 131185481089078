// Navbar.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebaseConfig.js';
import { getFirestore, collection, getDocs, query, where, limit } from 'firebase/firestore';
import './navbar.css';
import DayzeCoin from './DayzeCoin.js';
import { Dropdown, Menu } from 'antd';
import logo from './assets/images/dayze-logo.svg';
import defaultAvatar from './assets/images/default-avatar.svg';
import dayzeCoin50 from './assets/dayzecoin/50-dayzecoin.png';
import dayzeCoin100 from './assets/dayzecoin/100-dayzecoin.png';
import dayzeCoin250 from './assets/dayzecoin/250-dayzecoin.png';
import dayzeCoin500 from './assets/dayzecoin/500-dayzecoin.png';
import dayzeCoin1000 from './assets/dayzecoin/1000-dayzecoin.png';

function handlePillClick(link) {
  window.location.href = link;
}

const Navbar = ({ userProfile }) => {
  const [isSearchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const [showSearchInput, setShowSearchInput] = useState(false);

  const toggleSearchInput = () => {
    setShowSearchInput(!showSearchInput);
  };

  const performSearch = async () => {
    const db = getFirestore();
    let searchResultsArray = [];

    if (searchTerm.startsWith('@')) {
        const startAtHandle = searchTerm.slice(1);
        const endAtHandle = startAtHandle + '\uf8ff';

        const handleQuery = query(
            collection(db, 'userProfiles'),
            where("handle", ">=", startAtHandle),
            where("handle", "<=", endAtHandle),
            limit(8)
        );

        const handleResults = await getDocs(handleQuery);
        searchResultsArray = handleResults.docs.map(doc => ({ ...doc.data(), type: 'handle' }));
    } else {
        const startAtTerm = searchTerm;
        const endAtTerm = startAtTerm + '\uf8ff';

        const nameQuery = query(
            collection(db, 'userProfiles'),
            where("name", ">=", startAtTerm),
            where("name", "<=", endAtTerm)
        );
        const handleQuery = query(
            collection(db, 'userProfiles'),
            where("handle", ">=", startAtTerm),
            where("handle", "<=", endAtTerm)
        );

        const nameResults = await getDocs(nameQuery);
        const handleResults = await getDocs(handleQuery);

        const nameArray = nameResults.docs.map(doc => ({ ...doc.data(), type: 'name' }));
        const handleArray = handleResults.docs.map(doc => ({ ...doc.data(), type: 'handle' }));
        
        searchResultsArray = [...nameArray, ...handleArray].slice(0, 8); // Take top 8 results combining both
    }

    setSearchResults(searchResultsArray);
};

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSearchInputChange = (e) => {
    const lowerCaseValue = e.target.value.toLowerCase();
    setSearchTerm(lowerCaseValue);
    performSearch(lowerCaseValue); // Make sure performSearch uses the lowercase value
  };

  const profileImageUrl = userProfile && userProfile.profileImageUrl;
  const uid = auth.currentUser ? auth.currentUser.uid : null;

  const menu = (
    <Menu className="dark-mode">
      <Menu.Item>
        <Link className="menu-link" to="/edit-profile">Edit Profile</Link>
      </Menu.Item>
      <Menu.Item>
        <Link className="menu-link" to="/settings">Settings</Link>
      </Menu.Item>
      <Menu.Item onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const dayzeCoinData = [
    { amount: 50, image: dayzeCoin50 },
    { amount: 100, image: dayzeCoin100 },
    { amount: 250, image: dayzeCoin250 },
    { amount: 500, image: dayzeCoin500 },
    { amount: 1000, image: dayzeCoin1000 },
  ];

  const pillButtonPrices = ['9.99', '14.99', '19.99', '29.99', '49.99'];

  const individualLinks = [
    'https://buy.stripe.com/14k9CXaVRaIoe0U148',
    'https://buy.stripe.com/aEU4iDfc7bMs6ys28d',
    'https://buy.stripe.com/cN28yT3tp3fW5uo6ou',
    'https://buy.stripe.com/6oE2av0hd6s86ys5kr',
    'https://buy.stripe.com/28obL5d3Z9Ek3mg28g',
  ];
  
const dropdownContent = (
  <div className="dropdown-content">
    <div className="centered-boxes">
      {dayzeCoinData.map((data, index) => (
        <div key={index} className="box">
          <img
            src={data.image}
            alt={`DayzeCoin Pile ${data.amount}`}
            style={{ width: '100px', height: '84px', position: 'absolute', top: '14%' }}
          />
          <span style={{ position: 'absolute', top: '62%', fontWeight: 'bold', fontSize: '1.2em', color: 'white', }}>{data.amount}</span>
          <button
            className="pill-button"
            onClick={() => handlePillClick(individualLinks[index])}
          >
            ${pillButtonPrices[index]}
          </button>
        </div>
      ))}
    </div>
  </div>
);

const searchDropdownMenu = (
  <Menu className="dark-mode">
    {searchResults.map((result, index) => (
      <Menu.Item key={index}>
        <Link to={`/${result.handle}`} style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={result.profileImageUrl || defaultAvatar} 
            alt={result.name} 
            style={{ width: '30px', height: '30px', borderRadius: '15px', marginRight: '10px' }}
          />
          <span>
            {result.name} {result.handle && `(@${result.handle})`}
          </span>
        </Link>
      </Menu.Item>
    ))}
  </Menu>
);

return (
  <div className="navbar">
    <div className="navbar-logo">
      <Link to="/home">
        <img src={logo} alt="Dayze Logo" />
      </Link>
    </div>

    <div className="navbar-search">
      {showSearchInput ? (
        <Dropdown overlay={searchDropdownMenu} visible={isSearchActive && searchResults.length > 0}>
          <div>
          <input
              type="text"
              className="search-input"
              placeholder="Search Dayze"
              value={searchTerm}
              onChange={handleSearchInputChange}
              onBlur={() => setTimeout(() => setSearchActive(false), 200)}
              onFocus={() => setSearchActive(true)}
            />
          </div>
        </Dropdown>
      ) : (
        <div onClick={toggleSearchInput}>
          🔍 
        </div>
      )}
    </div>

<div className="navbar-menu">
        <Dropdown overlay={dropdownContent} trigger={['click']}>
          <div className="dayze-coin-container">
            <DayzeCoin uid={uid} />
          </div>
        </Dropdown>
        <Dropdown overlay={menu} trigger={['click']}>
          <div
            className="profile-image"
            style={{
              backgroundImage: `url(${profileImageUrl || defaultAvatar})`,
            }}
          />
        </Dropdown>
    </div>
  </div>
);
};

export default Navbar;