// hooks/useUpdateEventCount.js
import { useEffect } from 'react';
import { db } from '../firebaseConfig.js';
import { doc, updateDoc } from 'firebase/firestore';

const useUpdateEventCount = (uid, events) => {
  useEffect(() => {
    const updateUserEventCount = async () => {
      // Ensure we have a valid user ID and events array
      if (!uid || !Array.isArray(events)) {
        return;
      }

      const eventCount = events.length;

      // Reference to the user's profile document in the 'userProfiles' collection
      const userProfileRef = doc(db, 'userProfiles', uid);

      try {
        // Update the user's profile with the new event count
        await updateDoc(userProfileRef, {
          eventCount: eventCount
        });
      } catch (error) {
        console.error("Error updating user's event count:", error);
      }
    };

    updateUserEventCount();
  }, [uid, events]); // Dependency array to trigger effect when userId or events change
};

export default useUpdateEventCount;
