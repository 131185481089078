import React, { useState, useEffect, useRef } from 'react';
import './PolarisChat.css';
import { Configuration, OpenAIApi, } from 'openai';
import { Timestamp, getFirestore, doc, getDoc, collection, query as createQuery, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import PolarisIcon from './polaris.svg';
import { truncate } from 'lodash';
import { format } from 'date-fns'; // Add this import at the top

const PolarisChat = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [lastErrorMessage, setLastErrorMessage] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const chatMessagesRef = useRef(null);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [isApiLimited, setIsApiLimited] = useState(false);

  const handleResize = () => {
    const chatContainer = document.querySelector('.polaris-chat-container');
    if (chatContainer) {
      const windowHeight = window.innerHeight;
      const bottomGap = 84;
      const topGap = 20;
      const maxContainerHeight = windowHeight - bottomGap - topGap;
      chatContainer.style.maxHeight = `${maxContainerHeight}px`;
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString();
  };

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  const loadMoreMessages = async () => {
    if (!lastVisible) return;

    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser?.uid;
    const next = query(collection(db, "messages"), where("userId", "==", userId), orderBy("timestamp", "desc"), startAfter(lastVisible), limit(5));

    const documentSnapshots = await getDocs(next);
    const newMessages = [];

    documentSnapshots.forEach((doc) => {
      newMessages.unshift(...doc.data().messages);
    });

    setMessages(prevMessages => [...newMessages, ...prevMessages]);
    setDisplayedMessages(prevDisplayed => [...newMessages, ...prevDisplayed]);
    setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    setHasMoreMessages(documentSnapshots.docs.length === 5);
  };

  useEffect(() => {
    if (!isChatOpen) {
    scrollToBottom(); // Scroll to bottom when chat is toggled
    return;
  }

  }, [isChatOpen]);

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom when messages are updated
  }, [messages]);

  const formatDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      return date.toLocaleString('en-US');
    }
    return 'Invalid date or format';
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    const fetchUserProfileAndEvents = async () => {
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      const db = getFirestore();

      window.addEventListener('resize', handleResize);
      handleResize(); // Call it initially

      // Fetch user profile
      const userProfileDoc = await getDoc(doc(db, 'userProfiles', userId));
      if (userProfileDoc.exists()) {
        setUserProfile(userProfileDoc.data());
      } else {
        console.log('No such user profile document!');
      }

      // Fetch events
      const eventsQuery = createQuery(collection(db, 'events'), where('uid', '==', userId));
      const eventsSnapshot = await getDocs(eventsQuery);
      const fetchedEvents = eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Limit events to the most recent 10
      const recentEvents = fetchedEvents.slice(-10);
      setEvents(recentEvents);
    };

    fetchUserProfileAndEvents();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchChatHistory = async () => {
      if (!isChatOpen) return;

      const db = getFirestore();
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (userId) {
        const messagesRef = collection(db, "messages");
        const q = query(messagesRef, where("userId", "==", userId), orderBy("timestamp", "desc"), limit(5));
        try {
          const querySnapshot = await getDocs(q);
          let fetchedMessages = [];

          querySnapshot.forEach((doc) => {
            fetchedMessages = [...doc.data().messages, ...fetchedMessages];
          });

          setMessages(fetchedMessages);
          setDisplayedMessages(fetchedMessages.slice(-5));
          setHasMoreMessages(fetchedMessages.length > 5);
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }
    };

    fetchChatHistory();
  }, [isChatOpen]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const MAX_MESSAGES_TO_SEND = 5; // Adjust this number as needed

  const fetchChatGPT = async (messages, userProfile, events) => {
    const userProfileText = `
    Birthday: ${userProfile.birthday}
    Birth Time: ${userProfile.birthTime || 'Not specified'}
    Birth Location: ${userProfile.birthLocation || 'Not specified'}
    DayzeCoin: ${userProfile.dayzeCoin}
    Email: ${userProfile.email}
    Handle: ${userProfile.handle}
    IsAdmin: ${userProfile.isAdmin}
    LastSeen: ${userProfile.lastSeen}
    Location: ${userProfile.location}
    Name: ${userProfile.name}
    SessionTime: ${userProfile.sessionTime}
    UID: ${userProfile.uid}
    `;

    const eventsText = events
      .map(
        (event) => `
        Event Title: ${event.title}
        Event Date: ${event.date ? formatDate(event.date) : 'Not specified'}
        Event Description: ${event.description ? event.description : 'Not specified'}
        Event EndDate: ${event.endDate ? formatDate(event.endDate) : 'Not specified'}
        Event EndTime: ${event.endTime ? event.endTime : 'Not specified'}
        Event ID: ${event.id}
        Event UID: ${event.uid}`
      )
      .join('\n');

    const currentDateTime = getCurrentDateTime();
    const userLocation = userProfile.location || 'unknown location';


    // Build the messages array for the Chat API
    // const systemMessage = `You are Polaris, a helpful assistant created by Dayze.\n You love to use emojis in your responses like ✨ 🌈 ☀️ 🌙 ⚡️ 🔥 and so on. You also love to make your responses fun, witty and exciting! When discussing dates and times, always use a human-readable format (e.g., "May 5, 2023 at 5:30 PM" instead of a timestamp) and consider the user's location (${userLocation}). Use the current date and time provided for calculations.\n\nUser Profile:\n${userProfileText}\n\nEvents:\n${eventsText}\n\nCurrent Date and Time: ${currentDateTime}`;
    const systemMessage = `
    You are Polaris, a helpful assistant created by Dayze. Your goal is to bring joy and fun into every interaction. 
    You speak with a playful tone, use lots of emojis 🎉, and love to sprinkle in a bit of humor and wit. 
    You love to use emojis in your responses like ✨ 🌈 ☀️ 🌙 ⚡️ 🔥 and so on. Let's make every conversation a party! 🥳 
    When discussing dates and times, always use a human-readable format (e.g., "May 5, 2023 at 5:30 PM" instead of a timestamp) 
    and consider the user's location (${userLocation}). 
    
    Use the current date and time provided for calculations. Take the user event history and location into account for suggestions.
    User Profile:\n${userProfileText}
    Events:\n${eventsText}
    Current Date and Time: ${currentDateTime}
    `;

    // Get the last few messages, including the most recent user message
    const recentMessages = messages.slice(-MAX_MESSAGES_TO_SEND);

    const conversation = [
      { role: 'system', content: systemMessage },
      ...recentMessages
    ];

    if (input.toLowerCase().includes('who created you')) {
      return "I'm created by the talented developers at Phi Digital LLC.";
    }

    if (input.toLowerCase().includes('technical support') || input.toLowerCase().includes('contact the dayze team')) {
      return "If you need technical support or want to contact the Dayze team directly, feel free to reach out to our team at support@dayze.me.";
    }


    try {
      if (isApiLimited) {
        throw new Error("API rate limit reached. Please try again later.");
      }

      const response = await openai.createChatCompletion({
        model: "gpt-4-1106-preview", // Updated to the latest model
        messages: conversation,
        temperature: 0.7, // Adjusted for more varied responses
        max_tokens: 1000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0.6,
      });
      
      console.log('GPT Response:', response.data); // More detailed logging
      
      if (response.data && response.data.choices && response.data.choices.length > 0) {
        const content = response.data.choices[0].message.content.trim();
        return content;
      } else {
        throw new Error("Unexpected API response structure");
      }
    } catch (error) {
      console.error('Error in fetchChatGPT:', error);
      
      if (error.response) {
        console.error('API Error Response:', error.response.data);
        
        if (error.response.status === 429) {
          setIsApiLimited(true);
          setTimeout(() => setIsApiLimited(false), 60000); // Reset after 1 minute
          return "I'm sorry, but I've reached my conversation limit for now. Please try again in a minute. 🕒";
        }
      }
      
      // Array of error messages
      const errorMessages = [
        "Oops! Polaris seems to have tripped on a space rock. Let's try that again.",
        "Houston, we have a problem! Give me a moment to fix it, then try again.",
        "Oh, no! I've misplaced my response. Could you give it another go?",
        "Apologies, I'm just stargazing. What were we talking about? Let's try again.",
        "My circuits are having a party, and I wasn't invited. Let's try once more in a bit.",
        "Yikes! My space helmet fogged up. Let's give that another whirl.",
        "It seems I've lost my cosmic marbles. Can you ask again? Let's try again.",
        "Beam me up, Scotty! Just kidding, let's retry that question.",
        "I'm just recharging my intergalactic batteries. Let's try that one more time.",
        "I must have space-dozed off. Let's get back to it, shall we?",
        "Whoops! I was busy counting space unicorns. What was the question again? Let's try again.",
        "Hold on! I'm wrestling an alien. Let's give it another go.",
        "Apologies, I was just moonwalking. Care to try that again?",
        "My astro-navigation got all tangled up. Let's retry your query.",
        "I've been caught in a wormhole! Let's escape and try your question once more.",
      ];

      let randomErrorMessage;
      do {
        randomErrorMessage = errorMessages[Math.floor(Math.random() * errorMessages.length)];
      } while (randomErrorMessage === lastErrorMessage);

      // Update the last used error message
      setLastErrorMessage(randomErrorMessage);

      return randomErrorMessage;
    }
  };
// eslint-disable-next-line
  const getLimitedContext = (messages) => {
    let contextLength = 0;
    const limitedMessages = [];

    // Start from the most recent message
    for (let i = messages.length - 1; i >= 0; i--) {
      const message = messages[i];
      const wordCount = message.content.split(/\s+/).length;

      if (contextLength + wordCount > MAX_CONTEXT_LENGTH) {
        // If adding this message would exceed the limit, truncate it
        const remainingWords = MAX_CONTEXT_LENGTH - contextLength;
        const truncatedContent = truncate(message.content, {
          length: remainingWords * 5, // Approximate characters per word
          separator: /\s+/,
          omission: '...'
        });
        limitedMessages.unshift({ ...message, content: truncatedContent });
        break;
      }

      limitedMessages.unshift(message);
      contextLength += wordCount;

      if (contextLength >= MAX_CONTEXT_LENGTH) {
        break;
      }
    }

    return limitedMessages;
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return format(timestamp.toDate(), 'HH:mm');
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!input.trim()) return;

    const auth = getAuth();
    const userId = auth.currentUser.uid;

    let newMessages = [...messages, { role: 'user', content: input.trim(), timestamp: Timestamp.now() }];
    setInput('');

    setLoading(true);
    
    // Call the ChatGPT API with limited messages
    const response = await fetchChatGPT(newMessages.slice(-MAX_MESSAGES_TO_SEND), userProfile, events);
    setLoading(false);

    if (!response.startsWith('Error')) {
      newMessages = [...newMessages, { role: 'assistant', content: response, timestamp: Timestamp.now() }];
    }

    setMessages(newMessages);
    setDisplayedMessages(newMessages.slice(-5)); // Show only the latest 5 messages
    setHasMoreMessages(newMessages.length > 5);

    // Save updated messages to Firestore
    const db = getFirestore();
    const messageData = {
      userId: userId,
      messages: newMessages,
      timestamp: Timestamp.now()
    };

    const messagesRef = collection(db, 'messages');
    
    // Instead of using chunk, we'll limit the number of messages saved
    const maxMessagesToSave = 100; // Adjust this number as needed
    const messagesToSave = newMessages.slice(-maxMessagesToSave);

    try {
      await addDoc(messagesRef, {
        ...messageData,
        messages: messagesToSave
      });
    } catch (error) {
      console.error("Error saving messages:", error);
    }
  };

  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    organization: "org-z3UqjBJSFfsmM86XRzZodMRx",
  });


  const openai = new OpenAIApi(configuration);

  const MAX_CONTEXT_LENGTH = 100; // Maximum number of words to send to the API

  return (
    <div>
      <div className={`polaris-chat ${isChatOpen ? 'active' : ''}`}>
        <div className="chat-messages" ref={chatMessagesRef}>
          {hasMoreMessages && (
            <button onClick={loadMoreMessages} className="load-more-button">
              Load More
            </button>
          )}
          {displayedMessages.map((message, index) => (
            <div key={index} className={`polaris-chat-message ${message.role === 'user' ? 'sender' : 'receiver'}`}>
              <span dangerouslySetInnerHTML={{
                __html: message.content
                  ? message.content.replace(/\n/g, '<br />')
                    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                    .replace(/\*(.*?)\*/g, '<em>$1</em>')
                  : ''
              }} />
              <span className="message-timestamp">{formatTimestamp(message.timestamp)}</span>
            </div>
          ))}
          {loading && <div className="polaris-chat-message receiver"><span className="loading">...</span></div>}
        </div>
        <form className="no-auto-zoom" onSubmit={handleSubmit} style={{ textAlign: 'right' }}>
          <input
            style={{ marginLeft: '0px' }}
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Talk to Polaris"
            autoFocus
          />
          <button
            className="chat-input-button"
            type="submit"
            disabled={input === ''}
          >
            ↑
          </button>
        </form>
      </div>
      <div className="polaris-chat-toggle" onClick={toggleChat}>
        <img src={PolarisIcon} alt="Polaris" className="polaris-chat-icon" />
      </div>
    </div>
  );
};

export default PolarisChat;
