// src/components/DayzeCoin.js
import React, { useState, useEffect } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import dayzeCoinImage from './assets/images/dayzecoin.svg';


const DayzeCoin = ({ uid }) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (uid) {
      const db = getFirestore();
      const userDocRef = doc(db, 'userProfiles', uid);
  
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          setBalance(doc.data().dayzeCoin || 0);
        } else {
          setBalance(0);
        }
      });
  
      return () => unsubscribe();
    }
  }, [uid]);

  return (
    <div className="dayze-coin-container">
    <img
  src={dayzeCoinImage}
  alt="DayzeCoin"
  className="dayze-coin-image"
/>
    <strong>{balance}</strong>
  </div>
  );
};

export default DayzeCoin;
