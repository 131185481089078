import React, { useState, useEffect } from 'react';
import { Button, notification } from 'antd';
import EditEventModal from './EditEventModal.js';
import { auth } from './firebaseConfig.js';
import { Timestamp, serverTimestamp } from "firebase/firestore";
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import EndButton from './EndButton.js';
import moment from 'moment';
import './styles/YourEvents.css';

const EventListItem = ({ event, onDelete, onEdit, now, uid, onEventSave }) => {
    const [timeSinceEvent, setTimeSinceEvent] = useState('');
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isPastEvent, setIsPastEvent] = useState(false);

    useEffect(() => {
        const updateTimeSinceEvent = () => {
            const eventDateTime = new Date(Math.floor(event.date.toDate().getTime() / 1000) * 1000);
            const timeDiff = eventDateTime - now;
            const endDateTime = event.endDate ? event.endDate.toDate() : null;

            if (endDateTime) {
                const endTimeDiff = endDateTime - now;
                setIsPastEvent(endTimeDiff < 0);
            } else {
                setIsPastEvent(false);
            }

            const isPastEvent = timeDiff < 0;
            const isFutureEvent = timeDiff > 0;

            const days = Math.floor(Math.abs(timeDiff) / (1000 * 60 * 60 * 24));
            const hours = Math.floor((Math.abs(timeDiff) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((Math.abs(timeDiff) % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((Math.abs(timeDiff) % (1000 * 60)) / 1000);

            if (isPastEvent) {
                setTimeSinceEvent(`${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${seconds}s`);
            } else if (isFutureEvent) {
                setTimeSinceEvent(`in ${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${seconds}s`);
            } else {
                setTimeSinceEvent("Now");
            }
        };

        updateTimeSinceEvent();

        const interval = setInterval(() => {
            updateTimeSinceEvent();
        }, 1000);

        return () => clearInterval(interval);
    }, [event, now]);

    const handleEnd = async (e) => {
        e.stopPropagation();
    
        const db = getFirestore();
        const eventRef = doc(db, 'events', event.id);
    
        const firestoreEventEndDateTime = moment().toDate();
    
        await updateDoc(eventRef, {
            endDate: firestoreEventEndDateTime ? Timestamp.fromDate(firestoreEventEndDateTime) : serverTimestamp(),
            endTime: firestoreEventEndDateTime ? Timestamp.fromDate(firestoreEventEndDateTime) : serverTimestamp(),
        });
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete(event.id);
    
        // Show a success notification
        notification.success({
            message: 'Event Deleted',
            description: 'The event has been successfully deleted.',
            duration: 4, // Duration in seconds
            placement: 'bottom', // Placement of the notification
            className: 'ant-notification-notice-dark'
        });
    };

    return (
        <div>
             <div className={`event-item${isPastEvent ? ' past-event' : ''}`}>
                <p className="event-title">{event.title}</p>
                <p className="event-time">{timeSinceEvent}</p>
                <EditEventModal
                    uid={auth.currentUser ? auth.currentUser.uid : null}
                    event={event}
                    open={isEditModalVisible}
                    handleCloseEditModal={() => setIsEditModalVisible(false)}
                    onEventSave={onEventSave}
                />
                {!isPastEvent && <EndButton onClick={(event) => { event.stopPropagation(); handleEnd(event); }} />}
                <Button onClick={(event) => { event.stopPropagation(); handleDelete(event); }}>x</Button>
            </div>
        </div>
    );
};

export default EventListItem;

