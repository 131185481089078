import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Form, Select, message } from 'antd';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { getFirestore, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import defaultAvatar from './assets/images/default-avatar.svg';
import './styles/PersonModal.css';

const { Option } = Select;

const EditPersonModal = ({ uid, person, open, handleCloseEditModal, onPersonSave }) => {
    const [name, setName] = useState(person.name);
    const [birthday, setBirthday] = useState(person.birthday);
    const [profileImage, setProfileImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(person.imageUrl || defaultAvatar);
    const [firstMet, setFirstMet] = useState(person.firstMet);
    const [notes, setNotes] = useState(person.notes || '');
    const [isSaving, setIsSaving] = useState(false);
    const [isOpen, setIsOpen] = useState(open);
    const [firstMetLocation, setFirstMetLocation] = useState(person.firstMetLocation || '');
    const [address, setAddress] = useState(person.address || '');
    const [occupation, setOccupation] = useState(person.occupation || '');
    const [gender, setGender] = useState(person.gender || '');

    useEffect(() => {
        setIsOpen(open);
        if (open) {
            setDisplayImage(person.imageUrl || defaultAvatar);
            setGender(person.gender || '');
        }
    }, [open, person]);

    const handleOk = async () => {
        setIsSaving(true);
    
        try {
            const db = getFirestore();
            const personRef = doc(db, 'people', person.id);
            const updatedPersonData = {
                name,
                gender,
                birthday,
                firstMetLocation,
                notes,
                address,
                occupation,
            };
    
            if (firstMet !== undefined) {
                updatedPersonData.firstMet = firstMet;
            }
    
            if (profileImage) {
                const profileImageUrl = await uploadProfileImage(person.id, profileImage);
                updatedPersonData.imageUrl = profileImageUrl;
            }
    
            await updateDoc(personRef, updatedPersonData);
            onPersonSave({
                ...person,
                ...updatedPersonData,
            });
            
            message.success('Person updated successfully');
        } catch (error) {
            console.error('Error updating person in Firestore:', error);
            message.error('Failed to update person');
        } finally {
            setIsSaving(false);
            handleCloseEditModal();
        }
    };

    const uploadProfileImage = async (personId, imageFile) => {
        const storage = getStorage();
        const storageRef = ref(storage, `profileImages/${personId}`);
        await uploadBytes(storageRef, imageFile);
        return getDownloadURL(storageRef);
    };

    const handleCancel = () => {
        handleCloseEditModal();
    };

    const handleDelete = async () => {
        try {
            const db = getFirestore();
            const personRef = doc(db, 'people', person.id);
            await deleteDoc(personRef);
            handleCloseEditModal();
        } catch (error) {
            console.error('Error deleting person:', error);
        }
    };

    return (
        <Modal className='event-modal'
            title="Edit Person"
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="delete" onClick={handleDelete} type="text" danger>
                    Delete Profile
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleOk}
                    icon={isSaving ? <LoadingOutlined /> : <CheckCircleOutlined />}
                    disabled={isSaving}
                >
                    Save
                </Button>
            ]}
        >
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Form className="no-auto-zoom">
                    <Form.Item label="Profile Image">
                        <img
                            src={displayImage}
                            alt="Profile"
                            width="100"
                            height="100"
                            style={{ marginBottom: '1rem', borderRadius: '50%' }}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                setProfileImage(e.target.files[0]);
                                setDisplayImage(URL.createObjectURL(e.target.files[0]));
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className='form-input'
                            type="text"
                            value={name}
                            placeholder=" "
                            onChange={(e) => setName(e.target.value)}
                        />
                        <label className="form-label">Name</label>
                    </Form.Item>
                    <Form.Item label="Gender">
                        <Select
                            value={gender}
                            onChange={(value) => setGender(value)}
                            placeholder="Select gender"
                        >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Trans">Trans</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className='form-input'
                            type="date"
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                        />
                        <label className="form-label">Birth Date</label>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className='form-input'
                            type="datetime-local"
                            value={firstMet}
                            onChange={(e) => setFirstMet(e.target.value)}
                        />
                        <label className="form-label">First Met</label>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className='form-input'
                            type="text"
                            value={firstMetLocation}
                            placeholder=" "
                            onChange={(e) => setFirstMetLocation(e.target.value)}
                        />
                        <label className="form-label">First Met Location</label>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className='form-input'
                            type="text"
                            value={address}
                            placeholder=" "
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <label className="form-label">Address</label>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className='form-input'
                            type="text"
                            value={occupation}
                            placeholder=" "
                            onChange={(e) => setOccupation(e.target.value)}
                        />
                        <label className="form-label">Occupation</label>
                    </Form.Item>
                    <Form.Item>
                        <Input.TextArea
                            className='form-input-textarea'
                            value={notes}
                            placeholder=" "
                            onChange={(e) => setNotes(e.target.value)}
                            autoSize={{ minRows: 3, maxRows: 10 }}
                            style={{ height: '200px' }}
                        />
                        <label className="form-label-textarea">Notes</label>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );  
};

export default EditPersonModal;